
import { Observable } from 'rxjs';
import { UserService } from '../../../_services/user.service';
import { AuthService } from '../../../../views/pages/auth/auth.service';
import { Router } from '@angular/router';
// import { AuthService } from '../../../_services/auth.service';
export function appInitializer(userService:UserService, authService: AuthService)
{
    console.log(window.location.href);
   const url: any = window.location.href;
    return ()=> new Promise(resolve =>{
        if (url.indexOf('zomato') != -1 ) {
            console.log('this is rezolved');
            return resolve(true);
        }
        const urlParams = new URLSearchParams(window.location.search);
        console.log('urlParams', urlParams);
        const code = urlParams.get('code');
        console.log('code', code)
        if (code && code != "") {
            resolve(true);
        }
    const refresh_token: string = localStorage.getItem('refreshToken');
    if (refresh_token && refresh_token != '' && refresh_token != 'undefined') {
        userService.getTokens(refresh_token).subscribe(response => {
            // console.log('getTokens', response);
            const token = {
                IdToken: response.id_token,
                RefreshToken: response.RefreshToken
            }
            authService.setTokens(token);
           // authService.startTokenUpdateTimer(0.1);
            authService.getUserInfoANDRolePermissions({userName:''}).subscribe(response => {
                authService.setPermissions(response);
                authService.startTokenUpdateTimer(15);
            }).add(resolve);
        });
    }  else{
        setTimeout(() => {
            resolve('foo');
          }, 300);
    }
   });

    
}