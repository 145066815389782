export class PngTableConfigModel {
	title: string;
	index: string;
	cols: Col[];
	method?: any;
}

export class Col {
	field: string;
	display: boolean;
	header?: string;
	globalSearch?: boolean;
	link?: string;
	fieldSearch?: boolean;
	type?: any;
}
