export class CustomerModel {
	id: number;
	name: string;
	final_name: string;
	created_at: string;
	updated_at: string;
	applicant_id: string;
	partner_code: string;
	customer_uid: string;
	customer_code: string;
	joining_date: string;
	email: string;
	mobile: string;
	pin_code: string;
	city: string;
	state: string;
	pan: string;
	aadhaar: number;
	date_of_birth: string;
	onboarded_on: string;
	address: string;
	additional_data: string;
	ext_created_at: string;
	ext_updated_at: string;
	partner_name: string;
	rectification_fields: string;
	partner_uid: string;
	rectification_reason: string;
	hid: string;
	ai_joined_on?:string;
	shop_photo_url?:string;
	shop_video_url:string;
	lead_id?:any;
	final_sub_occupation?:any;
	final_business_environment?:any;
	final_business_type?:any;
}
