import { ProductVersionModel } from './product-version.model';

export class ProductMappingModel {
	id: number;
	lender_id: number;
	tnc_id: number;
	partner_id: number;
	version_id: number;
	ace_config_id: number;
	cohort_format_id: number;
	code: string;
	fallback_product_code: string;
	multi_product_code:string;
	kyc_policy: string;
	active: boolean;
	blacklist: boolean;
	blacklisted_at: string;
	name: string;
	uid: string;
	slug: string;
	created_at: string;
	updated_at: string;
	offer_disabled: boolean;
	approval_disabled: boolean;
	disbursal_disabled: boolean;
	pincode_blacklisted: boolean;
	auto_kyc_verify: boolean;
	auto_disburse: boolean;
	disburse_to_customer: boolean;
}
