export class FileViewModel {
    id: number;
    uploaded_at: string;
    s3_path: string;
    uploaded_by_user_id: number;
    status: string;
    summary: Text;
    log: Text;
    upload_type: string;
    date: string;
    uid: string;
    output_s3_path: string;
    file_name: string;
    created_at: string;
    updated_at: string;
    imei: string;
}

