import { Injectable } from "@angular/core";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { ElasticSearchQueryModel } from "../_models";
import { NgxPermissionsService } from "ngx-permissions";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { saveAs } from "file-saver";
import { AuthService } from "../../views/pages/auth/auth.service";

@Injectable({
	providedIn: "root",
})
export class UtilsService {
	user: any;
	constructor(private toastr: ToastrService, private permissionsService: NgxPermissionsService, private http: HttpClient, private authService: AuthService) {
		// this.user = JSON.parse(localStorage.getItem('user'));
		this.user = authService.user;
	}

	getEnumKeys(enumObject) {
		return Object.keys(enumObject).filter((f) => !isNaN(Number(f)));
	}

	errorToastr(err) {
		// return (JSON.stringify(err.error.error).replace(/[|&;$%@"<>(\[|\]|\{|\})+'",]/g, "").replace(":", ": "));
		// tslint:disable-next-line: forin
		for (const i in err.error.error) {
			this.toastr.error("Error", i + " " + err.error.error[i].join(". " + i + " "));
		}
	}

	sampleFile(fileType: string, partner: string): Observable<any> {
		const url = `${environment.urls.api.base}partners/${partner}/sample_downloads?file_type=${fileType}`;
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		const accessToken: any = localStorage.getItem("accessToken");
		if (accessToken) {
			headers = headers.set("Token", `Bearer ${accessToken}`);
			headers = headers.set("Accept", "text/csv");
		}
		// console.log('sampleFile accessToken', accessToken);
		try {
			return this.http.get(url, { headers, responseType: "blob" });
		} catch (e) {
			console.log("error", e);
		}

		// window.open(url, '_blank');
	}

	downloadFile(data: any) {
		/* const blob = new Blob([data], { type: 'text/csv' });
		const url= window.URL.createObjectURL(blob);
		window.open(url, '_blank'); */
		saveAs(data, "sample.csv");
	}
	convertDateFormat(fromFormat: string, toFormat: string) {
		// return moment(fromFormat).format(toFormat);
		return moment(fromFormat).isValid() ? moment(fromFormat).format(toFormat) : "";
	}

	getValFromPath(obj, path) {
		path.split(".").forEach((item) => (obj = obj[item] ? obj[item] : ""));
		return obj;
	}

	checkPermission(perm): boolean {
		return this.permissionsService.getPermissions()[perm] ? true : false;
	}

	userIsPartner(): boolean {
		this.user = this.authService.user;
		if (this.user) {
			return this.user.role === "PARTNER";
		}
		return false;
	}

	userIsLender(): boolean {
		this.user = this.authService.user; // JSON.parse(localStorage.getItem('user'));
		if (this.user) {
			return this.user.role === "LENDER" || this.user.role === "LENDER-V2";
		}
		return false;
	}

	userIsAdmin(): boolean {
		if (this.user) {
			return this.user.role === "ADMIN";
		}
		// tslint:disable
		return false;
	}

	userHaveAllPartnerPortalAccess(): boolean {
		this.user = this.authService.user;
		if (this.user) {
			return this.userIsAdmin() || this.user.role === "BUSINESS" || this.user.role === "BPA" || this.user.role === "CONVERSION_KYC_ACTION";
		}
		return false;
	}

	public getStateList(): any {
		return ["ANDHRA PRADESH", "ANDAMAN & NICOBAR ISLANDS", "ARUNACHAL PRADESH", "ASSAM", "CHATTISGARH", "BIHAR", "CHANDIGARH", "DADRA & NAGAR HAVELI", "DAMAN & DIU", "DELHI", "UTTARAKHAND", "GOA", "GUJARAT", "HARYANA", "TELANGANA", "HIMACHAL PRADESH", "JAMMU & KASHMIR", "RAJASTHAN", "JHARKHAND", "KARNATAKA", "KERALA", "MADHYA PRADESH", "MAHARASHTRA", "MANIPUR", "MEGHALAYA", "MIZORAM", "NAGALAND", "ODISHA", "PONDICHERRY", "PUNJAB", "SIKKIM", "TAMIL NADU", "TRIPURA", "UT", "UTTAR PRADESH", "WEST BENGAL"];
	}

	public getKycStatusList(): any {
		return ["REJECT", "ACCEPT", "KYC_APPROVED", "KYC_AUTO_APPROVED", "KYC_ON_HOLD", "KYC_REJECTED", "PAN_AUTO_APPROVED", "PAN_AUTO_REJECTED", "PAN_REJECTED", "KYC_SYSTEM_REJECTED", "KYC_AUTO_REJECTED", "KYC_UNDER_PROCESS", "PAN_ON_HOLD", "KYC_MANUAL_REJECTED", "PENDING"].sort();
	}

	public getLoanStatusList(): any {
		return [
			{ name: "Requested", value: "requested" },
			{ name: "Offered", value: "offered" },
			{ name: "QC Pending", value: "kyc_on_hold" },
			// {name: 'QC On Hold', value: 'kyc_on_hold'},
			{ name: "Re KYC", value: "re_kyc" },
			{ name: "QC Approved", value: "approved" },
			{ name: "Pending Approval", value: "disbursal_put_on_hold" },
			{ name: "Approval On Hold", value: "disbursal_put_on_hold" },
			{ name: "Disbursal Stuck", value: "disbursal_given_up" },
			{ name: "Disbursed", value: "partially_repaid" },
			{ name: "Loan Closed", value: "fully_repaid" },
			{ name: "QC Rejected", value: "rejected" },
			{ name: "Application Expired", value: "rejected" },
			{ name: "Discounted", value: "discounted" },
			{ name: "Cancelled", value: "cancelled" },
			{ name: "Written-off", value: "written_off" },
			{ name: "Mandate Pending", value: "accepted" },
			{ name: "Mandate Rejected", value: "accepted" },
			{ name: "Pending Disbursal", value: "disbursal_approved" },
			{ name: "Offer Rejected", value: "not_offered" },
			{ name: "Journey In-Progress", value: "requested" },
			{ name: "Fully Repaid", value: "fully_repaid" },
		].sort((a, b) => a.name.localeCompare(b.name));
	}

	public getLenderLoanStatusList(): any {
		return [
			{ name: "Pending Disbursal", value: "disbursal_approved" },
			{ name: "Pending Approval", value: "disbursal_put_on_hold" },
			{ name: "Disbursed", value: "partially_repaid" },
			{ name: "Fully Repaid", value: "fully_repaid" },
			{ name: "Cancelled", value: "cancelled" },
		].sort();
	}

	public getEsQueryTemplate(): ElasticSearchQueryModel {
		return {
			index: null,
			body: {
				query: { bool: { must: [], should: [] } },
				sort: [],
				aggs: {
					total_count: { value_count: { field: "_id" } },
				},
				user: {},
			},
			_source: [],
			size: 5,
			from: 0,
			filterPath: ["aggregations.total_count.value", "hits.total.value", "hits.hits._source", "hits.hits._id"],
			sort: ["t:desc"],
		};
	}

	statusMapping = {
		offered: "Offered",
		requested: "Journey In Progress",
		accepted: "Mandate Pending",
		accepted_with_reason: "Mendate Rejected",
		kyc_on_hold: "QC Pending",
		kyc_on_hold_with_reason: "QC On Hold",
		rejected: "QC Rejected",
		rejected_with_reason: "Application Expired",
		disbursal_put_on_hold: "Pending Approval",
		disbursal_put_on_hold_with_reason: "Approval On Hold",
		disbursal_approved: "Pending Disbursal",
		disbursal_initiated: "Disbursal Stuck",
		disbursal_expired: "Disbursal Stuck",
		partially_repaid: "Disbursed",
		// disbursed: "Disbursed",
		fully_repaid: "Loan Closed",
		re_kyc: "Re KYC",
		written_off: "Written-off",
		not_offered: "Offer Rejected",
		discounted: "Discounted",
		approved: "QC Approved",
		cancelled: "Cancelled",
	};
}
