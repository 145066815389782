import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {environment} from './../../../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class ReportService {
	// private esBaseUrl = environment.urls.elastic.base;
	private esBaseUrl = environment.urls.elastic.lambda;
	private apiBaseurl = environment.urls.api.base;
	private loan_db_name = environment.urls.elastic.indices.loans;
	private repayment_db_name = environment.urls.elastic.indices.repayments;

	constructor(private http: HttpClient) {
		console.log('production_environment:', environment.production);
	}

	public getDisbursementsReport(): Observable<any> {
		const request_body = {
			query: {
				bool: {
					must: {
						exists: {
							field: 'disbursal_utr'
						}
					}
				}
			}
		};

		// tslint:disable-next-line:max-line-length
		/* return this.http.post(this.esBaseUrl +
			this.loan_db_name +
			'/_search?' +
			'size=10000' +
			'&filter_path=' +
			'hits.hits._source.id,' +
			'hits.hits._source.disbursed_at,' +
			'hits.hits._source.disbursal_amount,' +
			'hits.hits._source.applicant_id,' +
			'hits.hits._source.product_uid,' +
			'hits.hits._source.partner_uid,' +
			'hits.hits._source.name,' +
			'hits.hits._source.amount,' +
			'hits.hits._source.disbursal_utr,' +
			'hits.hits._source.amount_repaid,' +
			'hits.hits._source.lender_uid,' +
			'hits.hits._source.partner_code,' +
			'hits.hits._source.borrower_loan_number'
			, request_body); */
			let body = {
				index: this.loan_db_name,
				body: {
				  query: {
					bool: {
					  must: [],
					},
				  },
				  sort: [],
				  aggs: {
					total_count: {
					  value_count: {
						field: '_id',
					  },
					},
				  },
				  user: {},
				},
				_source: [
				  'id',
				  'disbursed_at',
				  'disbursal_amount',
				  'applicant_id',
				  'product_uid',
				  'partner_uid',
				  'name',
				  'amount',
				  'disbursal_utr',
				  'amount_repaid',
				  'lender_uid',
				  'partner_code',
				  'borrower_loan_number',
				],
				size: 10000,
				from: 0,
				filterPath: [],
				sort: [],
			  };
			  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public reconExpectedHash(searchParams: any): Observable<any> {
		/* const recon_expeceted_sum = {
			query: {
				bool: {
					must: [
						{
							bool: {
								must: [
									{
										term: {
											category: {
												value: 'repay',
												boost: 1.0
											}
										}
									}
								],
								adjust_pure_negative: true,
								boost: 1.0
							}
						},
						{
							bool: {
								must: [
									{
										term: {
											partner_code: {
												value: searchParams.partner_code,
												boost: 1.0
											}
										}
									},
									{
										range: {
											paid_at: {
												from: searchParams.from,
												to: searchParams.to,
												include_lower: true,
												include_upper: false,
												boost: 1.0
											}
										}
									}
								],
								adjust_pure_negative: true,
								boost: 1.0
							}
						}
					],
					adjust_pure_negative: true,
					boost: 1.0
				}
			},
			aggs: {
				group_by_month: {
					date_histogram: {
						field: 'paid_at',
						interval: 'day',
						time_zone: '+05:30'
					},
					aggs: {
						amount: {
							sum: {
								field: 'amount'
							}
						}
					}
				}
			}
		};
		return this.http.post(this.esBaseUrl + environment.urls.elastic.indices.repayments + '/_search?', recon_expeceted_sum); */
		let body = {
			index: this.repayment_db_name,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  }, 
				},
				group_by_month: {
				  date_histogram: {
				   field: 'paid_at',
				   interval: 'day',
				   time_zone: '+05:30',
				 },
				 aggs: {
				  amount: {
					  sum: {
					  field: 'amount',
					 },
				   },
				 },
			   },
			  },
			  user: {},
			},
			_source: [],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: [],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public reconReceivedHash(searchParams: any): Observable<any> {
		// const recon_received_sum = {
		// 	query: {
		// 		bool: {
		// 			must: [
		// 				{
		// 					bool: {
		// 						must: [
		// 							{
		// 								term: {
		// 									txn_type: {
		// 										value: 'C',
		// 										boost: 1.0
		// 									}
		// 								}
		// 							},
		// 							{
		// 								bool: {
		// 									should: [
		// 										{
		// 											term: {
		// 												reversal: {
		// 													value: false,
		// 													boost: 1.0
		// 												}
		// 											}
		// 										},
		// 										{
		// 											bool: {
		// 												must_not: [
		// 													{
		// 														exists: {
		// 															field: 'reversal',
		// 															boost: 1.0
		// 														}
		// 													}
		// 												],
		// 												adjust_pure_negative: true,
		// 												boost: 1.0
		// 											}
		// 										}
		// 									],
		// 									adjust_pure_negative: true,
		// 									boost: 1.0
		// 								}
		// 							}
		// 						],
		// 						adjust_pure_negative: true,
		// 						boost: 1.0
		// 					}
		// 				},
		// 				{
		// 					bool: {
		// 						must: [
		// 							{
		// 								term: {
		// 									partner_code: {
		// 										value: searchParams.partner_code,
		// 										boost: 1.0
		// 									}
		// 								}
		// 							},
		// 							{
		// 								range: {
		// 									posted_at: {
		// 										from: searchParams.from,
		// 										to: searchParams.to,
		// 										include_lower: true,
		// 										include_upper: true,
		// 										boost: 1.0
		// 									}
		// 								}
		// 							}
		// 						],
		// 						adjust_pure_negative: true,
		// 						boost: 1.0
		// 					}
		// 				}
		// 			],
		// 			adjust_pure_negative: true,
		// 			boost: 1.0
		// 		}
		// 	},
		// 	aggs: {
		// 		group_by_month: {
		// 			date_histogram: {
		// 				field: 'posted_at',
		// 				interval: 'day'
		// 			},
		// 			aggs: {
		// 				amount: {
		// 					sum: {
		// 						field: 'amount'
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// };
		// return this.http.post(this.esBaseUrl + environment.urls.elastic.indices.bank_transactions + '/_search?', recon_received_sum);
		let body = {
			index: environment.urls.elastic.indices.bank_transactions,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: [],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: [],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public getReconsReport(searchParams: any): Observable<any> {
		const reconHashes = forkJoin(
			this.reconExpectedHash(searchParams),
			this.reconReceivedHash(searchParams)
		);
		const subscribe = reconHashes;
		return reconHashes;
	}

	public getRepaymentsReport(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.get(this.esBaseUrl +
			this.repayment_db_name +
			'/_search?' +
			'size=10000' +
			'&filter_path=' +
			'hits.hits._source.id,' +
			'hits.hits._source.amount,' +
			'hits.hits._source.applicant_id,' +
			'hits.hits._source.product_uid,' +
			'hits.hits._source.partner_uid,' +
			'hits.hits._source.partner_name,' +
			'hits.hits._source.disbursed_at,' +
			'hits.hits._source.amount_repaid,' +
			'hits.hits._source.lender_uid,' +
			'hits.hits._source.category,' +
			'hits.hits._source.borrower_loan_number,' +
			'hits.hits._source.amount_repaid_to_date,' +
			'hits.hits._source.ref_no'
		);
	}

	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token',`Bearer ${accessToken}`);
		}
		return {headers};
	}
}

// '&sort-by=' + searchParams.sort_by + '&sort-order=' + searchParams.sort_order +
