import {PartnerModel as Partner} from '../partner/partner.model';
import {CreditModel as Credit} from './credit.model';
import { ProductVersionModel as Version} from './product-version.model';
import { CommercialModel as Commercial} from './commercial.model';
import { DetailModel as Detail} from './detail.model';
import { FldgModel as Fldg} from './fldg.model';

export const VersionModel = Version;
export const CreditModel = Credit;
export const CommercialModel = Commercial;
export const DetailModel = Detail;
export const FldgModel = Fldg;

export class ProductModel {
	id?: number;
	created_at: string;
	updated_at: string;
	partner_id: number;
	code: string;
	partner_uid: string;
	uid: string;
	name: string;
	status: any;
	versions?: Version [];
	partners?: Partner [];
}
