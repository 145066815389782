// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import {Router} from '@angular/router';
import { AuthService } from '../../../../pages/auth/auth.service';
import {UserService} from '../../../../../core/_services/user.service'

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private router: Router, private authService: AuthService,private userService: UserService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user = this.authService.user; // JSON.parse(localStorage.getItem('user'));
	}

	/**
	 * Log out
	 */
	logout() {
		this.router.navigate(['/auth/login']);
		localStorage.setItem('refreshToken','');
		const user: any = this.authService.user;
		this.userService.logOut({ userName: user.userName }).subscribe(data => {
		}, error => {
			console.log(error,"error")
		});
	}
}
