import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatPaginator, MatSort, MatTableDataSource, PageEvent, MatSelect, MatOption, MatCheckbox, ThemePalette } from '@angular/material';
import { ElasticSearchQueryModel, SMTConfigModel } from '../../../../core/_models';
import { ElasticSearchService, LenderMappingService, LoanService, PartnerService, ProductService ,CkycService, CustomerService} from '../../../../core/_services/';
import { UtilsService } from '../../../../core/_global/utils.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { smtColTypeEnum } from '../../../../core/_enum';
import { SmtSortColumnComponent } from './smt-sort-column/smt-sort-column.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { AuthService } from '../../../pages/auth/auth.service';
import { MatDialog } from '@angular/material';
import moment from 'moment';

@Component({
	selector: 'kt-smart-mat-table',
	templateUrl: './smart-mat-table.component.html',
	styleUrls: ['./smart-mat-table.component.scss'],
	providers: [MatBottomSheet],
	encapsulation: ViewEncapsulation.None
})
export class SmartMatTableComponent implements OnInit {

	@Input() config: SMTConfigModel;
	@Output() cellClickEvent = new EventEmitter();
	@Output() actionClickEvent = new EventEmitter();

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('select', { static: true }) choose: MatSelect;

	allSelected = false;
	displayedColumns = [];
	displayedColumnsOG = [];
	dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

	cols: any[] = [];
	globalSearchFeilds: any[] = [];
	globalSearchFeildsDisplay: any[] = [];
	filters: any = {};

	queryBody: ElasticSearchQueryModel = this.utils.getEsQueryTemplate();
	totalRecords = 0;
	totalFetched = 0;

	partnersCodeList = [];
	partnersHash: any[] = [];
	productsHash: any[] = [];
	lenderList = [];
	lenderHash: any[] = [];
	loanStatusList = [];
	search_text=""
	onLoanPage:boolean=false
	onAdminListingPage:boolean=false
	loanUrl=""
	adminUrl=""
	selectedFilters:any={}
	page:any={'page_number':1,'per_page': 5}
	lenderStatus=["disbursal_put_on_hold", "disbursal_approved",  "partially_repaid", "fully_repaid" ,  "written_off","disburse_initiated","cancelled"]
	CurrentDate=moment()
	showLenderDate={'begin':moment(this.CurrentDate).format('YYYY-MM-DD'),'end':moment(this.CurrentDate).format('YYYY-MM-DD')}
	lenderDisbursedDate=moment(this.CurrentDate).format('YYYY-MM-DD')+' - '+ moment(this.CurrentDate).format('YYYY-MM-DD')
	reportsAvailable=[]
	// loadings
	getCSVLoading = false;
	loading = false;
	bottomSheetRef: MatBottomSheetRef;
	showFilters = this.utils.userIsLender();
	colTypeEnum = smtColTypeEnum;
	selectedReport=null

	// Bottom Sheet
	bottomSheetCols: MatBottomSheetRef;

	// Button
	showButton = false;

	//selectValue
	continueButton = true
	changeLender = ""
	rowData = []
	checkboxes = []
	allData = []
	showModal = false
	spinner = false
	showLenderChange = this.router.url === '/loan/kyc' 
	selectAll = false

	user = JSON.parse(localStorage.getItem("user"));
	currentUser:any

	// fields = ['approved', 'disbursal_put_on_hold', 'fully_repaid', 'partially_repaid', 'discounted', 'disbursal_given_up', 'rejected', 'written_off'];
	fields = ['approved',
		, 'cancelled'
		, 'disbursal_expired'
		, 'disbursal_given_up'
		, 'disbursal_put_on_hold'
		, 'disbursed'
		, 'discounted'
		, 'fully_repaid'
		, 'kyc_verified'
		, 'lender_approved'
		, 'partially_repaid'
		, 'rejected'
		, 'written_off'];
	// user;

	constructor(
		private esService: ElasticSearchService,
		private changeDetectorRef: ChangeDetectorRef,
		public utils: UtilsService,
		private toastr: ToastrService,
		private bottomSheet: MatBottomSheet,
		private ngbModal: NgbModal,
		private partnerService: PartnerService,
		private productService: ProductService,
		private lenderMappingService: LenderMappingService,
		public router: Router,
		private authService: AuthService,
		private loanService: LoanService,
		private customerService:CustomerService,
		private ckycService: CkycService,
		public dialog: MatDialog
	) {
		const user = this.authService.user;
		this.currentUser=user
		console.log("user",user);
	}

	ngOnInit() {
		this.parseConfig();
		this.getData();
	}




	private parseConfig() {

		// Get columns to be displayed from config
		this.config.cols.forEach(x => x.display && this.displayedColumnsOG.push(x.field));
		if (this.config.actions) {
			this.displayedColumnsOG.push('actions');
		}

		this.displayedColumns = this.displayedColumnsOG;

		console.log(this.router.url);

		// Generate ES Query body
		this.queryBody.index = this.config.index;
		this.config.cols.forEach(x => this.queryBody._source.push(x.field));

		this.cols = this.config.cols;

		this.cols.forEach(item => {
			if (item.globalSearch) {
				this.globalSearchFeilds.push(item.field);
				this.globalSearchFeildsDisplay.push(item.header);
			}
			switch (item.type) {
				case this.colTypeEnum.PARTNER_CODE:
				case this.colTypeEnum.PARTNER_UID: {
					this.getPartnersHash();
					break;
				}
				case this.colTypeEnum.LENDER_CODE: {
					this.getLendersHash();
					break;
				}
				case this.colTypeEnum.LOAN_STATUS: {
					this.getLoanStatusList();
					break;
				}
				case this.colTypeEnum.PRODUCT_UID: {
					this.getProductHash();
					break;
				}
			}
		});

		if (this.config.sortKey != null) {
			this.queryBody.sort = [this.config.sortKey + ":desc"];
		}
	}
	restrictSpecialChars(event) {
		const NO_SPECIAL_CHAR = /^[a-zA-Z0-9. ]+$/;
		const value = event.key.toString();
		const emailRegex = new RegExp(NO_SPECIAL_CHAR);
		return !(value && !emailRegex.test(value));
	}

	getData() {
		console.log("this",this.authService.user)
		if(this.authService.user.role==='LENDER'){
			this.selectedFilters.lender_code=this.authService.user.org
		}
		if(this.authService.user['email']==='arth01@arthdigital.net'){
		this.queryBody.body.query.bool.should.push(
			// {wildcard:{'applicant_id':'*'+'ADLSWCNGO'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLL53IWA'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLFNVDKK'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLSBEK5U'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADL52YZMK'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLWXMBSS'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADL9O9SL1'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLP8HITJ'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLSRUVW'+'*'}},
			// {wildcard:{'loan_uid':'*'+'9801341812-1e'+'*'}},
			// {wildcard:{'loan_uid':'*'+'8051843252-cd'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADL4ZF9DJ'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLCKO3B9'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLMOKGLC'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLM51MRU'+'*'}},
			// {wildcard:{'applicant_id':'*'+'ADLL3GDFL'+'*'}},

			{wildcard:{'applicant_id':'*'+'ADLE1GVCP'+'*'}},
			{wildcard:{'applicant_id':'*'+'ADLSBEK5U'+'*'}},
			{wildcard:{'applicant_id':'*'+'ADLSRUVWI'+'*'}},
			{wildcard:{'applicant_id':'*'+'ADLVBMKVM'+'*'}},
			{wildcard:{'applicant_id':'*'+'ADLQYLLPU'+'*'}},

			// { wildcard: { [item.field]: '*' + this.filters[item.field] + '*' } }
		);
				}
		this.addUserFilter();
		if (this.router.url == '/loan/kyc') {
			this.addKycLoansFilter();
			this.onLoanPage=true
			this.loanUrl='elastic/loan/verify_loans'
		}
		if (this.router.url == '/loan/kyc-verified') {
			this.addKycVerfiedLoansFilter();
			this.onLoanPage=true
			this.loanUrl='elastic/loan/approved'
		}
		if (this.router.url == '/report/disbursements') {
			this.addDisbursedLoansFilter();
		}
		if (this.router.url == '/loan') {
			this.addKYCverifiedAtLoansFilter();
			this.onLoanPage=true
		    // this.showButton = this.utils.userIsLender();
			this.loanUrl='elastic/loans'
			if(this.utils.userIsLender()){
				console.log("user",this.user)
				this.selectedFilters['status']=this.lenderStatus
				this.selectedFilters['lender_code']=this.currentUser.org
				// this.selectedFilters['disbursed_at']=this.lenderDisbursedDate
			}
			
		}
		if (this.router.url == '/loan/approve-reject') {
			this.addKYCverifiedAtLoansFilter();
			this.onLoanPage=true
		    this.showButton = true;
			this.loanUrl='elastic/loan/disburse_approval_loans'	
			this.selectedFilters.lender_code=this.authService.user.org
		}

		if (this.router.url == '/customer' || this.router.url=='/admin/customers') {
			this.onAdminListingPage=true
			this.adminUrl='elastic/customers/list'
		}
		if(this.router.url=='/admin/cohorts'){
			this.onAdminListingPage=true
			this.adminUrl='elastic/cohorts'
		}
		if(this.router.url=='/admin/preQualifications'){
			this.onAdminListingPage=true
			this.adminUrl='elastic/pre_qualifications'
		}
		if(this.router.url=='/admin/payments'){
			this.onAdminListingPage=true
			this.adminUrl='elastic/payments'
		}
		this.loading = true;
		if(this.onLoanPage && this.authService.user['email']!=='arth01@arthdigital.net'){
			this.loanService.getAllLoan(this.loanUrl,this.search_text,this.selectedFilters,this.page).subscribe((response:any)=>{
				console.log("all loans",response)
				for (let i = 0; i < response.data.length; i++) {
					this.checkboxes.push({

						checked: false
					})
				}
				this.allData = response.data
				this.dataSource.data = this.dataSource.data.slice();
				this.dataSource = new MatTableDataSource<any>(response.data);
				this.totalRecords = response.meta.totalRecords;
				this.totalFetched = response.meta.totalFetched;
			// 	if(this.router.url== '/loan/approve-reject'){
			// 	for(const data in this.allData){
			// 		// if(data['lead_id']){
			// 		this.loanService.getCredit(data['lead_id']).subscribe((res)=>{
	
			// 		})
			// 		// }
	
			// 	}	
			// }
				this.loading = false;
				this.getReportOptions()
				this.changeDetectorRef.detectChanges();
			},
			error => {
				console.log("from smatrt table", error)
				if (error.status != 401 && error.status != 403) {

					this.toastr.error('Something went wrong, please try again later.');
				}
				this.loading = false;
				this.changeDetectorRef.detectChanges();
			}
				)
			
		}

		else if(this.onAdminListingPage){
			this.customerService.getAdminList(this.adminUrl,this.search_text,this.selectedFilters,this.page).subscribe((response:any)=>{
				this.allData = response.data
				this.dataSource.data = this.dataSource.data.slice();
				this.dataSource = new MatTableDataSource<any>(response.data);
				this.totalRecords = response.meta.totalRecords;
				this.totalFetched = response.meta.totalFetched;
				this.loading = false;
				this.changeDetectorRef.detectChanges();
			},
			error => {
				console.log("from smatrt table", error)
				if (error.status != 401 && error.status != 403) {

					this.toastr.error('Something went wrong, please try again later.');
				}
				this.loading = false;
				this.changeDetectorRef.detectChanges();
			}
				)


		}

		else if (this.queryBody.index === "ckyc_reports") {
			this.ckycService.getData().subscribe(
				response => {
					this.allData = response.reports
					this.dataSource.data = this.dataSource.data.slice();
					this.dataSource = new MatTableDataSource<any>(response.reports);
					this.totalRecords = response.reports.length
					this.totalFetched = response.reports.length;
					this.loading = false;
					this.changeDetectorRef.detectChanges();
				},
				error => {
					console.error(error);
					console.log("from smatrt table", error)
					if (error.status != 401 && error.status != 403) {

						this.toastr.error('Something went wrong, please try again later.');
					}
					this.loading = false;
					this.changeDetectorRef.detectChanges();
				}
			);
			}
		else {
		this.esService.search(this.queryBody).subscribe(
			response => {
				this.allData = response.data
				this.dataSource.data = this.dataSource.data.slice();
				this.dataSource = new MatTableDataSource<any>(response.data);
				this.totalRecords = response.totalRecords;
				this.totalFetched = response.totalFetched;
				this.loading = false;
				this.changeDetectorRef.detectChanges();
			},
			error => {
				console.error(error);
				console.log("from smatrt table", error)
				if (error.status != 401 && error.status != 403) {

					this.toastr.error('Something went wrong, please try again later.');
				}
				this.loading = false;
				this.changeDetectorRef.detectChanges();
			}
		);
		}
		
	}

	search(field, value) {
		if(field==='global' && (this.onLoanPage || this.onAdminListingPage)){
			this.search_text=value
		}
		if(field!=='global' && (this.onLoanPage || this.onAdminListingPage)){
			if(field!=='status' && field!=='partner_code' && field!=='lender_code' && field!=='city' ){
			let start=moment(value.begin).format('YYYY-MM-DD')
			let end=moment(value.end).format('YYYY-MM-DD')
			value=start+' - '+end
			this.lenderDisbursedDate=value
			}
		if(field==='status'){
			// this.selectedFilters = {};
			const listOfLoanStatus = this.utils.getLoanStatusList();
			if(value==='disbursal_approved'){
				this.lenderStatus=['disbursal_approved','disburse_initiated']
			}
			else if(value===''){
			this.lenderStatus=["disbursal_put_on_hold", "disbursal_approved",  "partially_repaid", "fully_repaid" ,  "written_off",'disburse_initiated','cancelled']

			}
			else {
			this.lenderStatus=[listOfLoanStatus.find(item => item.name === value).value]

			}

			// this.selectedFilters[field]=[value]

			if(value === 'Mandate Pending'){
				this.selectedFilters['enach_token_status'] = 'pending';
			}

			if(value === 'Mandate Rejected'){
				this.selectedFilters['enach_token_status'] = 'rejected';
			}

			if(value === 'QC On Hold'){
				this.selectedFilters['kyc_status'] = 'KYC_ON_HOLD';
			}

			if(value === 'QC Rejected'){
				this.selectedFilters['kyc_verified_at'] = 'null';
			}

			if(value === 'Application Expired'){
				this.selectedFilters['reason'] = 'Loan offer expired';
			}

			if(value === 'Approval On Hold'){
				this.selectedFilters['lender_reason'] = 'not null';
			}
			

			value==='' ? this.selectedFilters[field]=this.lenderStatus: this.selectedFilters[field]=[listOfLoanStatus.find(item => item.name === value).value]

			if(value === 'Disbursal Stuck'){
				this.selectedFilters[field].push('disbursal_initiated');
				this.selectedFilters[field].push('disbursal_expired');
			}

			if(value === 'QC Approved'){
				this.selectedFilters[field].push('kyc_verified');
			}

			this.selectAll = false
			this.continueButton = true
			this.checkboxes.forEach(val => { val.checked = false });
			this.rowData = []
			// this.getReportOptions()
		}
		else{
		this.selectedFilters[field]=value
		}
		if(this.selectedFilters['status']){
		if(this.router.url === '/loan' && (this.selectedFilters['status'][0]==="disbursal_approved" || this.selectedFilters['status'][0]==="disbursal_put_on_hold" 
		|| this.selectedFilters['status'][0]==="partially_repaid") && this.selectedFilters['status'].length==1){
			this.showLenderChange=!this.utils.userIsLender()
			if(this.utils.userIsLender()){
				this.showButton=true
			}
		}
		if(this.router.url === '/loan' && this.selectedFilters['status'][0]!=="disbursal_approved" && this.selectedFilters['status'][0]!=="disbursal_put_on_hold" 
		&& this.selectedFilters['status'][0]!=="partially_repaid"){
			this.showLenderChange=false
			this.showButton=false
		}
		}
	}
		this.filters[field] = value;
		this.queryBody.from = 0;
		this.queryBody.body.query.bool.must = [];
		this.paginator.pageIndex = 0;
		this.cols.forEach(item => {
			if (this.filters[item.field]) {
				switch (item.type) {
					case this.colTypeEnum.DATE_RANGE: {
						this.queryBody.body.query.bool.must.push(
							{
								range: {
									[item.field]: {
										gte: this.utils.convertDateFormat(this.filters[item.field].begin, 'YYYY-MM-DD'),
										lte: this.utils.convertDateFormat(this.filters[item.field].end, 'YYYY-MM-DD')
									}
								}
							}
						);
						break;
					}
					case this.colTypeEnum.LOAN_STATUS:
					case this.colTypeEnum.PARTNER_UID:
					case this.colTypeEnum.PRODUCT_CODE:
					case this.colTypeEnum.PARTNER_CODE:
					case this.colTypeEnum.LENDER_CODE:
					case this.colTypeEnum.PRODUCT_UID:
					case this.colTypeEnum.LENDER_UID: {
						console.log(item.field);
						this.pushMustMatch([item.field], this.filters[item.field]);
						break;
					}
					case this.colTypeEnum.TEXT: {
						this.queryBody.body.query.bool.must.push(
							{ wildcard: { [item.field]: '*' + this.filters[item.field] + '*' } }
						);
						break;
					}
				}
			}
		});

		if (this.filters.global) {
			const globalSearchQuery = [];

			this.config.cols.forEach(item => {
				if (item.globalSearch) {
					// tslint:disable-next-line:max-line-length
					const wildcardSearch = (item.type === this.colTypeEnum.MOBILE) ? '??????' + this.filters.global + '*' : '*' + this.filters.global + '*';
					globalSearchQuery.push({ wildcard: { [item.field]: wildcardSearch } });
				}
			});

			this.queryBody.body.query.bool.must.push({ bool: { should: globalSearchQuery } });
		}

		this.addUserFilter();
		this.getData();
	}
	onKeyPress(event: KeyboardEvent, searchValue: string) {
		if (event.key === 'Enter') {
			this.search('global', searchValue);
		}
	}

	getCSV() {
		this.getCSVLoading = true;
		this.queryBody.body.user = this.authService.user; // JSON.parse(localStorage.getItem("user"));
		this.esService.getCSV(this.queryBody).subscribe();
		this.toastr.success('Please check your email for download link in few mins.');

		this.getCSVLoading = false;
	}

	getLendersHash() {
		this.lenderMappingService.getLenders().subscribe(
			response => {
				const lendersList = response.data;
				lendersList.reduce((dict, val) => {
					dict[val.id] = val.code;
					return dict;
				}, this.lenderHash);
			}
		);
	}

	getPartnersHash() {
		this.partnerService.getPartnerNames().subscribe(
			response => {
				const partnersList = response.data;
				partnersList.reduce((dict, val) => {
					dict[val.uid] = val.code;
					return dict;
				}, this.partnersHash);
				this.partnersCodeList = partnersList.map(item => item.code);
			}
		);
	}

	getProductHash() {
		this.productService.getProducts().subscribe(
			response => {
				const productsList = response.data;
				productsList.reduce((dict, val) => {
					dict[val.uid] = val.code;
					return dict;
				}, this.productsHash);
			}
		);
	}

	getLoanStatusList() {
		this.loanStatusList = this.utils.userIsLender()? this.utils.getLenderLoanStatusList() : this.utils.getLoanStatusList();
	}

	onPage(event: PageEvent) {
		console.log("page",event)
		if(this.onLoanPage || this.onAdminListingPage){
			this.page['page_number']=event.pageIndex+1
			this.page['per_page']=event.pageSize
		}
		this.queryBody.from = event.pageIndex * event.pageSize;
		this.queryBody.size = event.pageSize;
		this.getData();
	}

	onSort(event: any) {
		if (event.direction) {
			this.selectedFilters[event.active]=event.direction
			this.queryBody.body.sort = [{ [event.active]: { order: event.direction } }];
			this.getData();
		}
	}

	onCellClick(row, col) {
		this.cellClickEvent.emit({ row, col });
	}

	onActionClick(action, row) {
		this.actionClickEvent.emit({ action, row });
	}

	onFilterClick(hasBackdrop) {
		this.bottomSheet.open(SmtSortColumnComponent, {
			data: { cols: this.config.cols, displayedColumns: this.displayedColumns }
		}).afterDismissed().subscribe(result => {
			if (result) {
				this.displayedColumns = result;
				this.changeDetectorRef.detectChanges();
			}
		});
	}

	filterCollumn($event) {
		this.displayedColumns = this.queryBody._source = $event;
		this.changeDetectorRef.detectChanges();
	}

	removeUserSpecificColumnFilters() {
		if (!this.utils.userIsAdmin()) {
			this.removeColumnFilter(this.colTypeEnum.LENDER_CODE);
			this.removeColumnFilter(this.colTypeEnum.LENDER_UID);
			if (!this.utils.userIsLender()) {
				this.removeColumnFilter(this.colTypeEnum.PARTNER_CODE);
				this.removeColumnFilter(this.colTypeEnum.PARTNER_UID);
			}
		}
	}

	removeColumnFilter(key) {
		let itemIndex = -1
		this.config.cols.forEach((item, index) => {
			if (item.type == key) {
				itemIndex = index;
			}
		});
		if (itemIndex !== -1) {
			this.config.cols.splice(itemIndex, 1);
		}
	}

	addUserFilter() {

		this.cols.forEach(item => {
			if (this.utils.userIsLender() && item.type == this.colTypeEnum.LENDER_CODE) {
				this.pushMustMatch(item.field, this.authService.user.org);
			}
			else if (this.utils.userIsPartner() && item.type == this.colTypeEnum.PARTNER_CODE) {
				this.pushMustMatch(item.field, this.authService.user.org);
			}
		});
	}

	addKycLoansFilter() {
		this.pushMustMatch('status', 'kyc_on_hold');
	}

	addKycVerfiedLoansFilter() {
		this.pushMustMatch('status', 'kyc_verified');
	}

	addDisbursedLoansFilter() {
		this.pushMustExist('disbursed_at');
	}

	addKYCverifiedAtLoansFilter() {
		if (this.utils.userIsLender()) {
			// this.pushMustExist('kyc_verified_at');

			// this.queryBody.body.query.bool['should'] = [];
			this.queryBody.body.query.bool['filter'] = {};
			this.pushShouldCondition('status', this.fields);
			// for (const iterator of this.fields) {
			// this.pushShouldCondition('status', iterator);
			// }
		}
	}

	pushMustMatch(key, value) {
		let replaced = false;
		this.queryBody.body.query.bool.must.forEach(must => {
			if (must["match"] != null && must["match"][key] != null) {
				replaced = true;
				must["match"][key] = value
			}
		});
		if (!replaced) {
			this.queryBody.body.query.bool.must.push(
				{ match: { [key]: value } }
			);
		}
	}

	pushMustExist(key) {
		let replaced = false;
		this.queryBody.body.query.bool.must.forEach(must => {
			if (must["exists"] != null && must["exists"]["field"] != null) {
				replaced = true;
				must["exists"]["field"] = key
			}
		});
		if (!replaced) {
			this.queryBody.body.query.bool.must.push(
				{ exists: { ["field"]: key } }
			);
		}
	}

	pushShouldCondition(key, value): void {
		if(this.authService.user.role==='LENDER'){
		delete this.queryBody.body.query.bool['filter'];
		}

		else{
		this.queryBody.body.query.bool['filter'].terms = {};
		this.queryBody.body.query.bool['filter'].terms[key] = [];
		this.queryBody.body.query.bool['filter'].terms[key] = this.fields;
		this.queryBody.body.query.bool['filter'].terms.status.push(value);

		}
		return;

	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
		this.filterCollumn(this.displayedColumns);
		this.changeDetectorRef.detectChanges();
	}

	myClickFunction(event) {
		this.showModal = true
	}

	selectClicked(event) {
		this.changeLender = event.value
		this.cols[0].display = true;
		this.showButton = true;
	}

	toggleAllSelection(event) {
		if (this.allSelected) {
			this.choose.options.forEach((item: MatOption) => item.select());
		} else {
			this.choose.options.forEach((item: MatOption) => item.deselect());
		}

	}

	optionClick(event, data) {
		if (this.checkboxes[data].checked == true) {
			this.rowData.push(event.source.value.loan_uid)
		}
		else if (this.checkboxes[data].checked == false) {
			this.selectAll = false
			this.rowData = this.rowData.filter((item) => {
				return item !== event.source.value.loan_uid
			})
		}
		if (this.rowData.length > 0) {
			this.continueButton = false
		}
		if (this.rowData.length ===0) {
			this.continueButton = true
		}
	}

	update(result) {
		this.loanService.changeLender(this.rowData, this.changeLender).subscribe((response) => {
			this.toastr.success('Success', 'Records Updated');
			setTimeout(() => {
				this.spinner = false
				window.location.reload()
			}
				, 13000)
		}, (error) => {
			this.toastr.error('Failed', error);
			setTimeout(() => {
				this.spinner = false,
					window.location.reload()
			}
				, 1000)

		}
		)
		this.rowData = []
		this.checkboxes.forEach(val => { val.checked = false });

	}

	CheckAllOptions(event) {
		if (event.checked == true) {
			this.selectAll = true
			this.checkboxes.forEach(val => { val.checked = true });
			this.rowData = []
			this.allData.forEach((item, index) => {
				this.rowData.push(this.allData[index].loan_uid)
			})
			this.continueButton = false
		}

		if (event.checked == false) {
			this.selectAll = false
			this.continueButton = true
			this.checkboxes.forEach(val => { val.checked = false });
			this.rowData = []
		}

	}

	handleContinue() {
		this.showModal = false
		this.continueButton = true
		this.showButton = false
		this.spinner = true
		this.update(true)
	}

	handleClose() {
		this.showModal = false
	}
	
	downloadCkycReport(j) {
		window.location.href = this.allData[j].s3_path.url.toString()
	}

	lenderApprove(){
		this.loanService.approveByLender(this.rowData).subscribe((res)=>{
			this.toastr.success('Success', 'Records Updated');
			this.rowData = []
		    this.checkboxes.forEach(val => { val.checked = false });
			window.location.reload()
		// 	this.dataSource=new MatTableDataSource<any>([]);
		// this.parseConfig();
		// this.getData();
		

		},
		(error) => {
			this.toastr.error('Failed', error);}
			)
	}

	displayStatus(status,reason){
		if(status && reason){
			if(this.utils.statusMapping[`${status}_with_reason`]){
				return this.utils.statusMapping[`${status}_with_reason`];
			}
			return this.utils.statusMapping[status];
		}
		return this.utils.statusMapping[status];
	}

	downloadLenderCsv(report){
		let value=""
		if(this.selectedReport==='Pre Disbursement'){
       value='predisbursement'
		}
		else if(this.selectedReport==='Disbursement')
		{
        value='disbursment'
		}
		else{
         value='repayment'
		}
		
		this.loanService.downloadLenderCsv(this.rowData,value).subscribe((res:any)=>{
			if(res.business_details_report){
			window.location.href = res.business_details_report.toString()
			}
			if(res.customer_details_report){
				setTimeout(()=>{
			window.location.href = res.customer_details_report.toString()
		},1000)

			}
			if(res.disbursement_details_report){
				setTimeout(()=>{
			window.location.href = res.disbursement_details_report.toString()
				},1000)
			}
			if(res.collection_details_report){
				setTimeout(()=>{
			window.location.href = res.collection_details_report.toString()
				},1000)
			}
			this.rowData = []
			this.selectAll=false
			this.checkboxes.forEach(val => { val.checked = false });
			this.continueButton=true

		},
		(error) => {
			this.rowData = []
			this.selectAll=false
			this.checkboxes.forEach(val => { val.checked = false });
			this.continueButton=true
			this.toastr.error('Failed', error);
		
		}
			)
		// setTimeout(()=>{
		// 	this.selectedReport=null
		// 	this.changeDetectorRef.detectChanges()
		// },3000 )
	}

	getReportOptions(){
		if(this.lenderStatus[0]=='disbursal_put_on_hold'){
			this.reportsAvailable=['Pre Disbursement']
		}
		if(this.lenderStatus[0]=='partially_repaid'){
			this.reportsAvailable=['Pre Disbursement',"Disbursement",'Repayment']
		}
		if(this.lenderStatus[0]=='disbursal_approved'){
			this.reportsAvailable=['Pre Disbursement','Disbursement']
		}
		this.selectedReport=null

		// reportsAvailable={'Pre Disbusement':{permission:['disbursal_put_on_hold','disbursal_approved','partially_repaid','disburse_initiated']},
		// 'Disbursement':{permission:['disbursal_approved','partially_repaid','disburse_initiated']},'Repayment':{permission:['partially_repaid']}}


	}
	
}