export class LegacyModel {
	id: number;
	securities_description: Text;
	agreement_highlights: Text;
	risk_sharing_model: Text;
	ends_on: string;
	locked_at: string;
	loan_type: string;
	repay_short_desc: string;
	short_description: string;
	full_description: Text;
	allowed_contract_codes: string;
	currency: string;
	insurance_bundled: boolean;
	last_published_at: string;
	uat_complete: string;
	status: boolean;
	created_at: string;
	updated_at: string;
	name: string;
	repay_desc: Text;
}
