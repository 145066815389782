export class LenderModel {
	id: number;
	name: string;
	lender_cap: number;
	account_cap: number;
	daily_cap: number;
	monthly_cap: number;
	lender_type: string;
	created_at: string;
	updated_at: string;
	code: string;
}
