
export class CreditModel {
	id: number;
	created_at: string;
	updated_at: string;
	principal_max: string;
	principal_min: string;
	allow_multiple_active_loans: boolean;
	kyc_auto_verification: boolean;
	// kyc_policy: string;
	validate_crif_criteria: boolean;
	name: string;
	allow_loan_params_override: boolean;
	moratorium_days: number;
	principal_incr: string;
	principal_list: string [];
	custom_offer_validity: number;
	offer_valid_time: string;
	offer_type: string;
	loan_category: string;
	credit_line: boolean;
}
