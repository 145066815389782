// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable} from 'rxjs';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, UserService} from '../../../../core/_services';
import {NgxPermissionsService} from 'ngx-permissions';
// Sha256
import { sha256, sha224 } from 'js-sha256';
import { AuthService } from '../auth.service';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: '',
	PASSWORD: ''
};

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private returnUrl: any;

	constructor(
		private router: Router,
		private userService: UserService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private permissionsService: NgxPermissionsService,
		private authService: AuthService
	) {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('user');
		localStorage.removeItem('permissions');
		this.permissionsService.flushPermissions();
	}

	ngOnInit(): void {
		console.log('WWW-ATHOS - version 1.0.0');
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.loading = false;
	}

	initLoginForm() {
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			// tslint:disable-next-line:max-line-length
			// const initialNotice = `Use account <strong>${DEMO_PARAMS.EMAIL}</strong> and password <strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
			// this.authNoticeService.setNotice(initialNotice, 'info');
		}

		this.loginForm = this.fb.group({
			email: [DEMO_PARAMS.EMAIL, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	submit() {
		const controls = this.loginForm.controls;

		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;


		const authData = {
			userName: controls.email.value,
			// password: controls.password.value
			password: sha256(controls.password.value)
		};
		this.userService.login(authData).subscribe(data => {
			if (data.ChallengeName) {
				this.router.navigate(['/auth/change-password'], { queryParams: { userName: authData.userName, session: data.Session} });
			} else if (data.AuthenticationResult.IdToken && data.AuthenticationResult.RefreshToken) {
				// console.log('data.AuthenticationResult', data.AuthenticationResult);
				// localStorage.setItem('accessToken', data.AuthenticationResult.IdToken);
				this.authService.setTokens(data.AuthenticationResult);
				localStorage.setItem('refreshToken', data.AuthenticationResult.RefreshToken);
				this.getUserInfo(controls.email.value);
				this.authService.startTokenUpdateTimer(15);
			}
		}, error => {
			this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			this.loading = false;
		});


		// this.userService
		// 	.login(authData)
		// 	.pipe(
		// 		tap(user => {
		// 			if (user) {
		// 				this.store.dispatch(new Login({authToken: user.accessToken}));
		// 				this.router.navigateByUrl(this.returnUrl); // Main page
		// 			} else {
		// 				this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
		// 			}
		// 		}),
		// 		takeUntil(this.unsubscribe),
		// 		finalize(() => {
		// 			this.loading = false;
		// 			this.cdr.markForCheck();
		// 		})
		// 	)
		// 	.subscribe();
	}

	getUserInfo(userName) {
		this.userService.getUser({userName}).subscribe(data => {
			// localStorage.setItem('user', JSON.stringify(data));
			this.authService.user = data;
			this.getRolePermissions(data.role);
		}, error => {
			console.log(error, 'error');
		});
	}

	getRolePermissions(roleId) {
		this.userService.getRole(roleId).subscribe(data => {
			// localStorage.setItem('permissions', JSON.stringify(data.permissions));
			this.authService.permissions = data.permissions;
			this.permissionsService.loadPermissions(data.permissions);
			this.permissionsService.addPermission('LOGGED_IN');
			this.router.navigateByUrl(this.returnUrl);
		}, error => {
			console.log(error, 'error');
		});
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
