import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ElasticSearchService {

	constructor(private http: HttpClient) {
	}

	search(params): Observable<any> {
		return this.http.post(environment.urls.elastic.lambda + 'search', params, this.esHeaders());
	}
	getCSV(params): Observable<any> {
		return this.http.post(environment.urls.elastic.lambda + 'scroll', params, this.esHeaders());
	}
	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
	    let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		return {headers};
	}
}
