import {Injectable} from '@angular/core';
import * as elasticsearch from 'elasticsearch-browser';
import {environment} from './../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class BaseService {
	public esClient: elasticsearch.Client;

	constructor() {
		if (!this.esClient) {
			this.esConnect();
		}
	}

	private esConnect() {
		this.esClient = new elasticsearch.Client({
			host: environment.urls.elastic.base
		});
	}
}
