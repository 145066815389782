// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
// RxJS
import {finalize, takeUntil, tap, delay} from 'rxjs/operators';
import {Subject} from 'rxjs';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Auth
import {AuthNoticeService, UserService} from '../../../../core/_services';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	constructor(
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private userService: UserService,
		private toastr: ToastrService
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.forgotPasswordForm.controls;
		/** check form */
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const email = controls.email.value;
		this.resetPassword(email);
	}

	resetPassword(userName) {
		this.userService.forgotPasswordEmail({userName}).subscribe(
			response => {
				this.loading = false;
				this.toastr.success('Verification code has been mailed to given email id.', 'Success');
				this.cdr.detectChanges();
				this.router.navigate(['/auth/reset-password'], { queryParams: { userName} });
			},
			error => {
				if (error && error.error) {
					if (error.error.code === 'NotAuthorizedException') {
						this.toastr.error(error.error.message, 'Error');
						this.loading = false;
						delay(2000);
						this.router.navigate(['/auth/login']);
					} else {
						this.toastr.error(error.error.message, 'Error');
						this.loading = false;
						this.cdr.detectChanges();
					}
				} else {
					this.toastr.error('Please contact your system administrator.', 'Error');
					this.loading = false;
					delay(2000);
					this.router.navigate(['/auth/login']);
				}
			}
		);
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
