
export class DetailModel {
	id: number;
	created_at: string;
	updated_at: string;
	repay_model: string;
	deduction_percent?: any;
	daily_cap_percent?: any;
	starts_on: string;
	disbursal_type: string;
	category: string;
	product_type: string;
	enach_initiated_required: boolean;
	enach_success_required: boolean;
	enach_trigger_required:boolean;
	last_fact_effective_at?: any;
	schedule_algo: string;
	name: string;
	recovery_freq: string;
	recovery_installment_counts: string;
	recovery_days: any;
	insurance_bundled: boolean;
	repay_short_desc: string;
	short_description: string;
	skip_payment_advice: boolean;
	credit_limit_type: string;
	credit_limit_validity: number;
	credit_repayment_type: string;
	credit_pf_deduction_type: string;
	limit_product: boolean;
	comulative_principal: boolean;
	product_sub_type:string;
}
