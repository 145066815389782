export class LoanRepaymentModel {
	id: number;
	loan_uid: string;
	transaction_id: string;
	amount: number;
	status: string;
	paid_at: string;
	additional_data?: any;
	created_at: string;
	updated_at: string;
	ext_created_at: string;
	ext_updated_at: string;
	principal_repaid: number;
	fees_repaid: number;
	interest_repaid: number;
	penalty_repaid: number;
	extra_repaid: number;
	num: number;
	day: number;
	cycle: number;
	pcycle: number;
	effective_at: string;
	repayment_mode?: any;
	category: string;
	ref_no?: any;
}
