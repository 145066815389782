import {CustomerModel} from '..';

export class LoanModel {
	id: number;
	applicant_id: string;
	loan_uid: string;
	mobile_number: string;
	amount: number;
	due_at: string;
	approved_at: string;
	offered_at: string;
	rejected_at: string;
	created_at: string;
	customer_code: string;
	repayment_track: string;
	disbursal_scheduled_at: string;
	disbursed_at: string;
	closed_at: string;
	status: string;
	requested_at: string;
	amount_requested: string;
	amount_approved: string;
	acceptance_status: string;
	amount_offered: string;
	lender_code: string;
	partner_name: string;
	partner_code: string;
	customer?: any;

	disbursal_account_code: string;
	disbursal_account_bank_name: string;
	disbursal_account_number: string;
	disbursal_account_ifsc_code: string;
	disbursal_account_holder_name: string;
	disbursal_account_type: string;
	disbursal_sms_sent_at: string;

	accepted_at: string;
	kyc_on_hold_at: string;
	re_kyc_at: string;
	kyc_verified_at: string;
	rekyc_by: string;
	kyc_verified_by: string;
	kyc_admin_action_at: string;
	kyc_admin_action_by: string;
	kyc_admin_action: string;
	written_off_at: string;
	disbursal_approved_at: string;
	disbursal_on_hold_at: string;
	disbursal_given_up_at: string;
	discounted_at: string;
	amount_discounted: string;
	lender_approved_at: string;
	cancelled_at: string;
	amount_repaid: number;
	refund_charged: number;
	amount_remaining: number;
	lender?: any;
	partner?: any;
	lead_id?:any;
	total_due?:any;
	total_repaid?:any;
	lender_reason?:any
	security_charged: any;
	security_repaid: any;
}
