import { SourceAndMapMixin } from 'webpack-sources';
import { CommercialModel } from './commercial.model';
import { CreditModel } from './credit.model';
import { DetailModel } from './detail.model';
import { FldgModel } from './fldg.model';
import { LegacyModel } from './legacy.model';
import { LenderMappingModel } from '../lender-mapping/lender-mapping.model';

export class ProductVersionModel {
	id: number;
	created_at: string;
	updated_at: string;
	commercial_id: number;
	credit_id: number;
	detail_id: number;
	fldg_id: number;
	name: string;
	legacy_id: number;
	lender_mapping_ids?: number [];
	commercial: CommercialModel;
	credit: CreditModel;
	detail: DetailModel;
	fldg: FldgModel;
	lender_mappings?: LenderMappingModel [];
	legacy: LegacyModel;
}
