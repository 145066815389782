export class InfoModel {
	id: number;
	created_at: string;
	updated_at: string;
	partner_id: number;
	code: string;
	partner_uid: string;
	uid: string;
	name: string;
	status?: any;
}
