export enum ComputeMethodsEnum {
	ORIGINAL_PRINCIPAL_CYCLE,
	DECLINING_PRINCIPAL_CYCLE,
	DECLINING_COMPOUNDED_PRINCIPAL_CYCLE,
	ORIGINAL_PRINCIPAL_MONTHLY,
	DECLINING_PRINCIPAL_MONTHLY,
	DECLINING_COMPOUNDED_PRINCIPAL_MONTHLY,
	EDI_CYCLE_INTEREST_FLAT_PENALTY_DECLINING_ROUNDOFF_DISCOUNT,
	CYCLE_FLAT_PRINCIPAL_INTEREST_FLAT_OUTSTANDING_PRINCIPAL_PENALTY,
	ORIGINAL_PRINCIPAL_CYCLE_REFUND_DECLINING_PRINCIPAL_MONTHLY
}

export enum FinalFeeTypeEnum {
	MAX_OF_BOTH,
	MIN_OF_BOTH
}

export enum InitFeeTypeEnum {
	MAX_OF_BOTH,
	MIN_OF_BOTH
}

export enum PenaltyTypeEnum {
	MAX_OF_BOTH,
	MIN_OF_BOTH
}

export enum KycPolicyEnum {
	kyc_policy_strict_match,
	kyc_policy_manual,
	kyc_policy_ocr_only
}


export enum PngTableConfigColTypeEnum {
	CATEGORY,
	DATE,
	DATE_RANGE,
	LENDER_UID,
	LOAN_STATUS,
	MOBILE,
	PARTNER_CODE,
	LENDER_CODE,
	PARTNER_UID,
	PRODUCT_UID,
	PRODUCT_CODE,
	TEXT,
	TIME
}


export enum smtColTypeEnum {
	CATEGORY = 'CATEGORY',
	DATE = 'DATE',
	DATE_RANGE = 'DATE_RANGE',
	LENDER_UID = 'LENDER_UID',
	LOAN_STATUS = 'LOAN_STATUS',
	MOBILE = 'MOBILE',
	PARTNER_CODE = 'PARTNER_CODE',
	LENDER_CODE = 'LENDER_CODE',
	PARTNER_UID = 'PARTNER_UID',
	PRODUCT_UID = 'PRODUCT_UID',
	PRODUCT_CODE = 'PRODUCT_CODE',
	TEXT = 'TEXT',
	TIME = 'TIME'
}

export enum SubscriptionTypeEnum {
	MAX_OF_BOTH,
	MIN_OF_BOTH
}
