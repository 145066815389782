export class UserModel {
	userName: string;
	enabled: boolean;
	userStatus: string;
	email_verified: string;
	name: string;
	phone_number_verified: string;
	phone_number: string;
	role: string;
	email: string;
	type?: string;
	org?: string;
}
