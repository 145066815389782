export const environment = {
	env: 'staging',
	production: false,
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	urls: {
		api: {
			base: 'https://athos-api-gw.staging.arthapp.in/',
			sdk_api: 'https://sdk-api.staging.arthapp.in/',
			sdk: 'https://www.sdk.staging.arthapp.in/',
			user: 'https://gbp7uj3hz7.execute-api.ap-south-1.amazonaws.com/Prod/',
			old_url:'https://athos-api.staging.arthapp.in/',
			ice_url:'https://ice-api.staging.arthapp.in/',
			ovs_url:'https://ovs-api.staging.arthapp.in/',
			lms_url:'https://lms-api.staging.arthapp.in/v1/'
		},
		elastic: {
			bypass_es_auth: false,
			model_suffix: '',
			lambda: 'https://775ikug2g4.execute-api.ap-south-1.amazonaws.com/Prod/',
			base: 'https://search-arthapp-stage-elasticsearch-ythmn3ziltgjwt3gkztg7eivae.ap-south-1.es.amazonaws.com/',
			auth: '',
			indices: {
				product_versions: 'production_product_versions',
				product_commercials: 'production_product_commercials',
				product_credits: 'production_product_credits',
				tncs: 'production_product_tncs',
				product_details: 'production_product_details',
				product_fldgs: 'production_product_fldgs',
				partners: 'production_partners',
				lenders: 'production_lenders',
				bank_accounts: 'production_bank_accounts',
				disbursal_limits: 'production_disbursal_limits', // from API
				product_mappings: 'production_product_mappings',
				customers: 'production_customers',
				loans : 'production_loans',
				loan_requests: 'production_leads',
				spocs: 'production_spocs',
				partner_file_uploads: 'production_partner_file_uploads',
				prequalifications: 'production_customer_pre_qualifications',
				repayments: 'production_loan_repays',
				ace_configs: 'production_product_ace_configs',
				crif_data_requests: 'production_crif_reports',
				ace_algos: 'production_product_ace_algos',
				lender_mappings: 'lender_mappings_production',
				crif_data: 'production_crif_reports',
				ckyc_data: 'ckyc_reports',
				bank_transactions: 'production_bank_transactions',
				loan_monthly_interests: 'production_loan_monthly_interests',
				file_uploads: 'production_file_uploads',
				payment_advices: 'production_payment_advices',
				cohorts: 'production_cohorts'
			}
		}
	}
};
