export class AceConfigModel {
    id: number;
    name: string;
    algo_a1_id: number;
    algo_a2_id: number;
    algo_b1_id: number;
    algo_b2_id: number;
    algo_a_weightage: number;
    algo_b_weightage: number;
    algo_ab_weightage: number;
    a2_acceptance_threshold: number;
    b2_acceptance_threshold: number;
    created_at: string;
    updated_at: string;
}
