export class KycCustomerModel {
	country_code: string;
	name: string;
	father_name: string;
	city: string;
	state: string;
	mobile: string;
	email: string;
	address: string;
	gender: string;
	mcc_code?: any;
	customer_type?: any;
	borrower_type?: any;
	borrower_grade?: any;
	pincode: string;
	kyc_missing: string[];
	kyc_under_review: any[];
	kyc_rejected: any[];
	kyc_status: string;
	kyc_reason: string;
	created_at: string;
	updated_at: string;
	pan_card_url: string;
	photo_url: string;
	aadhar_front_url: string;
	aadhar_back_url: string;
	aadhaar_pdf_url: string;
	bank_account_name: string;
	bank_account_number: string;
	bank_ifsc_code: string;
	ocr_dob?: any;
	ocr_pincode?: any;
	ocr_address?: any;
	ocr_district?: any;
	ocr_state?: any;
	ocr_city?: any;
	last_admin_action?: any;
	last_admin_action_by?: any;
	ocr_pan_no?: any;
	ocr_pan_name?: any;
	ocr_pan_father_name?: any;
	ocr_pan_dob?: any;
	blacklist_code?: any;
	blacklisted_at?: any;
	bank_name?: any;
	bank_details_confirmed_at?: any;
	bank_details_rejected_at?: any;
	bank_details_reject_reason?: any;
	bank_account_type?: any;
	address_proof_type?: any;
	pan_verified_gender?: any;
	pan_status?: any;
	address_proof_status?: any;
	manual_address?: any;
	manual_state?: any;
	manual_city?: any;
	manual_pincode?: any;
	manual_comments?: any;
	final_name?: any;
	final_father_name?: any;
	final_dob?: any;
	final_pan?: any;
	final_address?: any;
	final_city?: any;
	final_state?: any;
	final_pincode?: any;
	final_poa_expiry_date?: any;
	pan_ocr_name_match?: any;
	pan_verify_name_match?: any;
	poa_ocr_name_match?: any;
	poa_ocr_expires_on?: any;
	mother_name?: any;
	marital_status?: any;
	purpose_of_loan?: any;
	ownership_type_shop?: any;
	ownership_type_residence?: any;
	date_of_birth: string;
	pan_card_no: string;
	aadhar_no: string;
	joined_on: string;
	signed_aadhaar_front_url: string;
	signed_aadhaar_back_url: string;
	signed_aadhaar_pdf_url: string;
	signed_pan_pdf_url: string;
	signed_pan_card_url: string;
	signed_photo_url: string;
	bank_statements: any[];
	bank_statement_confirmed_at?: any;
	bank_statement_rejected_at?: any;
	bank_statement_reject_reason?: any;
	t_last_admin_action?: any;
	kyc_policy?: any;
	payee_validation_status?: any;
	pan_verified?: any;
	payee_validation_data?: any;
	code: string;
	signed_ap_front_url: string;
	signed_ap_back_url: string;
	alternate_city: string;
  	alternate_pincode: string;
  	alternate_address: string;
  	alternate_state: string;
	sub_occupation?:string;
	final_sub_occupation?:string;
	shop_address?:string;
	final_shop_address?:string;
	final_shop_state?:string;
	final_shop_pincode?:string;
	final_business_type?:string;
	business_type?:string;
	final_business_environment?:string;
	business_environment?:string;
	current_address?:string;
	current_pincode?:string;
	final_current_address?:string;
	final_current_pincode?:string
}
