import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ProductService {
	// tslint:disable-next-line:max-line-length
	// private esBaseUrl = environment.urls.elastic.base;
	private esBaseUrl = environment.urls.elastic.lambda;
	private apiBaseUrl = environment.urls.api.base;
	private oldBaseUrl=environment.urls.api.old_url
	// Need to remove this 
	// private oldUrl='https://athos-api.staging.arthapp.in/'

	constructor(private http: HttpClient) {
		console.log('production_environment:', environment.production);
	}

	public ProductIndicesToUrl(data: string) {
		return data.split(' ').filter((val: string) => val.includes('production'));
	}

	public getProducts(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_mappings + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*,-hits.hits._source.infos,-hits.hits._source.versions,-hits.hits._source.partner');
		let body = {
			index: environment.urls.elastic.indices.product_mappings,
			body: {
				query: {
					bool: {
						must: [],
					},
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: '_id',
						},
					},
				},
				user: {},
			},
			_source: ['*', 'infos', 'versions', 'partner'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		};
		return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public getAllDocuments(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_mappings + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*,-hits.hits._source.infos,-hits.hits._source.versions,-hits.hits._source.partner');
		let body = {
			index: environment.urls.elastic.indices.product_mappings,
			body: {
				query: {
					bool: {
						must: [],
					},
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: '_id',
						},
					},
				},
				user: {},
			},
			_source: [],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		};
		return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public postProduct(postData: any) {
		return this.http.post(this.apiBaseUrl + 'product/infos', postData, this.esHeaders());
	}

	public getProductVersions(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_versions + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*');
		// let body = {
		// 	index: environment.urls.elastic.indices.product_versions,
		// 	body: {
		// 	  query: {
		// 		bool: {
		// 		  must: [],
		// 		},
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },
		// 	  user: {},
		// 	},
		// 	_source: ['*'],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.get(this.apiBaseUrl + 'elastic/partners/product_versions', this.esHeaders());

	}

	public getProductVersionsList(searchText,size,pageNumber): Observable<any>{
		let body = {
			"search_text": searchText,
			"per_page":size,
			"page_number": pageNumber
		}
		return this.http.post(this.apiBaseUrl + 'elastic/products/versions',body,this.esHeaders())
	}

	public getProductVersionDetail(id): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_versions + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*&q=_id:' + id);
		// let body = {
		// 	index: environment.urls.elastic.indices.product_versions,
		// 	body: {
		// 		query: {
		// 			match_phrase: { _id: id },
		// 		},
		// 		sort: [],
		// 		aggs: {
		// 			total_count: {
		// 				value_count: {
		// 					field: '_id',
		// 				},
		// 			},
		// 		},
		// 		user: {},
		// 	},
		// 	_source: [],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		// };
		return this.http.get(this.apiBaseUrl + `elastic/products/version?id=${id}`,  this.esHeaders());
	}

	public postProductVersion(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product/versions', postData, this.esHeaders());
	}

	public getCommercials(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_commercials + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*,-hits.hits._source.infos,-hits.hits._source.versions,-hits.hits._source.partner');
		// let body = {
		// 	index: environment.urls.elastic.indices.product_commercials,
		// 	body: {
		// 	  query: {
		// 		bool: {
		// 		  must: [],
		// 		},
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },
		// 	  user: {},
		// 	},
		// 	_source: ['*', 'infos', 'versions', 'partner'],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.get(this.apiBaseUrl + 'elastic/partners/commercials', this.esHeaders());

	}

	public postCommercial(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product/commercials', postData, this.esHeaders());
	}

	public getCredits(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_credits + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*,-hits.hits._source.infos,-hits.hits._source.versions,-hits.hits._source.partner');
		// let body = {
		// 	index: environment.urls.elastic.indices.product_credits,
		// 	body: {
		// 	  query: {
		// 		bool: {
		// 		  must: [],
		// 		},
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },	
		// 	  user: {},
		// 	},
		// 	_source: ['*', 'infos', 'versions', 'partner'],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.get(this.apiBaseUrl + 'elastic/partners/credits', this.esHeaders());

	}

	public getFldgs(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_fldgs + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*,-hits.hits._source.infos,-hits.hits._source.versions,-hits.hits._source.partner');
		// let body = {
		// 	index: environment.urls.elastic.indices.product_fldgs,
		// 	body: {
		// 	  query: {
		// 		bool: {
		// 		  must: [],
		// 		},
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },
		// 	  user: {},
		// 	},
		// 	_source: ['*', 'infos', 'versions', 'partner'],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.get(this.apiBaseUrl + 'elastic/partners/fldgs', this.esHeaders())
	}

	public postCredits(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product/credits', postData, this.esHeaders());
	}

	public getTnc(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.tncs + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*,-hits.hits._source.infos,-hits.hits._source.versions,-hits.hits._source.partner');
		// let body = {
		// 	index: environment.urls.elastic.indices.tncs,
		// 	body: {
		// 	  query: {
		// 		bool: {
		// 		  must: [],
		// 		},
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },
		// 	  user: {},
		// 	},
		// 	_source: ['*', 'infos', 'versions', 'partner'],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.get(this.apiBaseUrl + 'elastic/partners/tncs', this.esHeaders());
	}

	public postTnc(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'tncs', postData, this.esHeaders());
	}

	public previewTnc(postData: any): Observable<any> {
		// 	let headers = new HttpHeaders();
		// headers = headers.set('Accept', 'application/pdf');
		// 	return this.http.post(this.apiBaseUrl + 'tncs/preview', postData, {headers: headers});
		return this.http.post(this.apiBaseUrl + 'tncs/preview', postData, this.esHeaders());
	}

	public verifyProductMapping(id: number): Observable<any> {
		return this.http.patch(this.apiBaseUrl + 'product_mappings/' + id + '/verify', {}, this.esHeaders());
	}

	public getProductDetails(): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_details + '/_search/?sort=t:desc&size=10000');
		// let body = {
		// 	index: environment.urls.elastic.indices.product_details,
		// 	body: {
		// 	  query: {
		// 		bool: {
		// 		  must: [],
		// 		},
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },
		// 	  user: {},
		// 	},
		// 	_source: [],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.get(this.apiBaseUrl + 'elastic/partners/product_details', this.esHeaders());


	}

	public getProductDetailsList(searchText,size,pageNumber): Observable<any>{
		let body = {
			"search_text": searchText,
			"per_page":size,
			"page_number": pageNumber
		}

		return this.http.post(this.apiBaseUrl + 'elastic/products/details',body,this.esHeaders());
	}

	public getProductDetailsInfo(id): Observable<any>{
		return this.http.get(`${this.oldBaseUrl}elastic/products/detail?id=${id}`, this.esHeaders());

	}
	public getCreditDetailInfo(id): Observable<any>{
		return this.http.get(`${this.oldBaseUrl}elastic/products/credit?id=${id}`, this.esHeaders());

	}	public getProductAceConfigInfo(id): Observable<any>{
		return this.http.get(`${this.oldBaseUrl}elastic/products/ace_config?id=${id}`, this.esHeaders());

	}	public getProductfldgsInfo(id): Observable<any>{
		return this.http.get(`${this.oldBaseUrl}elastic/products/fldg?id=${id}`, this.esHeaders());

	}


	public getProductFldgs(): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_fldgs + '/_search/?sort=t:desc&size=10000');
		let body = {
			index: environment.urls.elastic.indices.product_fldgs,
			body: {
				query: {
					bool: {
						must: [],
					},
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: '_id',
						},
					},
				},
				user: {},
			},
			_source: [],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		};
		return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public postProductDetails(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product/details', postData, this.esHeaders());
	}

	public postProductFldg(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product/fldgs', postData, this.esHeaders());
	}

	// public getDatum(): Observable<any> {
	// 	return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_data + '/_search/?sort=t:desc&size=10000');
	// }

	public postDatum(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product/data', postData, this.esHeaders());
	}

	// public getRecoveries(): Observable<any> {
	// 	return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_recoveries + '/_search/?sort=t:desc&size=10000&filter_path=hits.hits.*');
	// }

	public postRecovery(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product/recoveries', postData, this.esHeaders());
	}

	public postProductMapping(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product_mappings', postData, this.esHeaders());
	}

	public putProductMapping(postData: any): Observable<any> {
		return this.http.put(this.apiBaseUrl + 'product_mappings/' + postData.id, postData);
	}

	public getProductMapping(id): Observable<any> {
		let body = {
			"partner_id": id
		}
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_mappings + '/_search?sort=t:desc&size=10000&q=partner_id:' + id);
		// let body = {
		// 	index: environment.urls.elastic.indices.product_mappings,
		// 	body: {
		// 	  query: {
		// 		match_phrase: { partner_id: id },
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },
		// 	  user: {},
		// 	},
		// 	_source: [],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.post(this.apiBaseUrl + '/elastic/partners/product_mappings', body, this.esHeaders());

	}

	public getFallbackProducts(partner_id): Observable<any> {
		/* const fallback_prod_query = {
			query: {
				bool: {
					must: [
						{ match: { 'lender.lender_type': 'direct' }},
						{ match: { partner_id }},
						{ match: { verified: true }}
					]
				}
			}
		};
		return this.http.post(this.esBaseUrl + environment.urls.elastic.indices.product_mappings + '/_search?', fallback_prod_query); */
		let body = {
			index: environment.urls.elastic.indices.product_mappings,
			body: {
				query: {
					//  match_phrase: { partner_id: partner_id }, // changed
					bool: {
						must: [

						]
					}
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: '_id',
						},
					},
				},
				user: {},
			},
			_source: [],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: [],
		};
		return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public getAceConfig(id): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.ace_configs + '/_search?sort=t:desc&size=10000&q=partner_id:' + id);
		let body = {
			index: environment.urls.elastic.indices.ace_configs,
			body: {
				query: {
					match_phrase: { partner_id: id },
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: '_id',
						},
					},
				},
				user: {},
			},
			_source: [],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		};
		return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public getAceConfigs(): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.ace_configs + '/_search?size=10000&sort=t:desc');
		// let body = {
		// 	index: environment.urls.elastic.indices.ace_configs,
		// 	body: {
		// 	  query: {
		// 		bool: {
		// 		  must: [],
		// 		},
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },
		// 	  user: {},
		// 	},
		// 	_source: [],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.get(this.apiBaseUrl + 'elastic/partners/ace_configs', this.esHeaders());

	}

	public getAceConfigList(searchText, size,pageNumber): Observable<any> {
		let body = {
			"search_text": searchText,
			"per_page":size,
			"page_number": pageNumber
		}
		return this.http.post(this.apiBaseUrl + 'elastic/products/ace_configs',body, this.esHeaders());
	}

	public getcreditList(searchText, size,pageNumber): Observable<any> {
		let body = {
			"search_text": searchText,
			"per_page":size,
			"page_number": pageNumber
		}
		return this.http.post(this.apiBaseUrl + 'elastic/products/credits',body, this.esHeaders());
	}

	public getFldgList(searchText, size,pageNumber): Observable<any> {
		let body = {
			"search_text": searchText,
			"per_page":size,
			"page_number": pageNumber
		}
		return this.http.post(this.apiBaseUrl + 'elastic/products/fldgs',body, this.esHeaders());
	}

	public getAlgos(): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.ace_algos + '/_search?size=1000&sort=t:desc');
		// let body = {
		// 	index: environment.urls.elastic.indices.ace_algos,
		// 	body: {
		// 		query: {
		// 			bool: {
		// 				must: [],
		// 			},
		// 		},
		// 		sort: [],
		// 		aggs: {
		// 			total_count: {
		// 				value_count: {
		// 					field: '_id',
		// 				},
		// 			},
		// 		},
		// 		user: {},
		// 	},
		// 	_source: [],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		// };
		// return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		return this.http.get(this.apiBaseUrl+'elastic/products/ace_algos',this.esHeaders())
	}

	public postAceConfig(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'product/ace_configs', postData, this.esHeaders());
	}

	public getProductCommercials(searchText, size,pageNumber): Observable<any> {
		let body = {
			"search_text": searchText,
			"per_page":size,
			"page_number": pageNumber
		}
		return this.http.post(this.apiBaseUrl + 'elastic/products/commercials',body, this.esHeaders());

	}

	public getCommercialsDetail(id): Observable<any> {
	
		return this.http.get(this.apiBaseUrl + `elastic/products/commercial?id=${id}`, this.esHeaders());

	}


	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		let accessToken: any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		return { headers };
	}
}
