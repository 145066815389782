// Angular
import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
// Layout
import {LayoutConfigService, SplashScreenService, TranslationService} from '../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';

// Auth
import {AuthNoticeService, UserService} from '../../../core/_services';
import {TranslateService} from '@ngx-translate/core';
import { AuthService } from './auth.service';

@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	headerLogo: string;
	isLoginVisible = false;
	loading = false;

	constructor(
		private router: Router,
		private translate: TranslateService,
		private el: ElementRef,
		private render: Renderer2,
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private route: ActivatedRoute,
		private splashScreenService: SplashScreenService,
		private userService: UserService,
		private authService: AuthService,
		private changeDetecterRef: ChangeDetectorRef
		) {

			this.route.queryParams
		.subscribe(params => {
		  const code = params.code;
		  console.log('params.code', code);
		  if (code) {
			  console.log('check code');
			  const authDataWithControl = { code };
			  this.cognitoAuthentication(authDataWithControl);
		  } else {
			this.isLoginVisible = true;
		  }
		});
	}

	cognitoAuthentication(authDataWithControl) {
		this.userService.samlLogin(authDataWithControl).subscribe(data => {
			console.log(data, 'data');
			if (data.error) {
				this.isLoginVisible = true;
				this.authNoticeService.setNotice(this.translate.instant('Invalid Code'), 'danger');
			}
			if (data.ChallengeName) {
				this.router.navigate(['/auth/change-password'], { queryParams: { code: authDataWithControl.code, session: data.Session} });
				this.isLoginVisible = true;
			} else if (data.id_token && data.refresh_token) {
				// const controls = this.loginForm.controls;
				/* console.log('data.id_token', data.id_token);
				localStorage.setItem('accessToken', data.id_token);
				this.authService.getUserInfo(''); */
				const tokens = {
					IdToken: data.id_token,
					RefreshToken: data.refresh_token
				};
				this.authService.setTokens(tokens);
				localStorage.setItem('refreshToken', data.refresh_token);
				this.authService.getUserInfoANDRolePermissions({userName: ''}).subscribe(response => {
					console.log('getUserInfoANDRolePermissions', response);
					this.authService.setPermissions(response);
					this.authService.startTokenUpdateTimer(15);
					this.router.navigateByUrl('/');
				});
				// this.authService.getUserInfo('');
			}
			this.changeDetecterRef.detectChanges();
		}, error => {
			console.log('i am here');
			this.isLoginVisible = true;
			this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			// this.loading = false;
		});
	}

	ngOnInit(): void {
		this.translationService.setLanguage(this.translationService.getSelectedLanguage());
		this.headerLogo = this.layoutConfigService.getLogo();

		this.splashScreenService.hide();
	}

	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
