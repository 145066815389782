
export class FldgModel {
	id: number;
	name: string;
	int_share_ai_disbursement_fldg: number;
	int_share_ai_closure_fldg: number;
	int_share_partner_disbursement_fldg: number;
	int_share_partner_closure_fldg: number;
	int_share_ai_disbursement_non_fldg: number;
	int_share_ai_closure_non_fldg: number;
	int_share_partner_disbursement_non_fldg: number;
	int_share_partner_closure_non_fldg: number;
	pf_share_ai_disbursement_fldg: number;
	pf_share_partner_disbursement_fldg: number;
	pf_share_ai_closure_fldg: number;
	pf_share_partner_closure_fldg: number;
	pf_share_ai_disbursement_non_fldg: number;
	pf_share_ai_closure_non_fldg: number;
	pf_share_partner_disbursement_non_fldg: number;
	pf_share_partner_closure_non_fldg: number;
	created_at: string;
	updated_at: string;
}
