export class CommercialModel {
	id: number;
	created_at: string;
	updated_at: string;
	interest_cycle_days: number;
	interest_max_cycles: number;
	interest_per_cycle: string;
	penalty_cycle_days: number;
	penalty_max_cycles: number;
	penalty_cycle_flat: string;
	penalty_cycle_percent: string;
	penalty_type: string;
	init_fee_flat: string;
	init_fee_percent: string;
	init_fee_type: string;
	final_fee_flat: string;
	final_fee_percent: string;
	final_fee_type: string;
	init_interest_deduction: boolean;
	exclusive_gst: boolean;
	round_off: boolean;
	name: string;
	compute_method: string;
	start_type: string;
	facilitation_fee_percent: string;
	facilitation_fee_flat: string;
	facilitation_fee_type: string;
	init_pf_deduction: boolean;
	init_security_emi_deduction: boolean;
	purchase_order_value:boolean;
	repayment_settlement_priority: string;
	principal_cycle_type: string;
	interest_cycle_type: string;
    principal_cycle_days: number;
	principal_max_cycles: number;
	principal_grace_cycles: number;
	interest_applied_on: string;
	subscription_cycle_days: number;
	subscription_max_cycles: number;
	subscription_cycle_flat: string;
	subscription_cycle_percent: string;
	subscription_type: string;
	enach_repayment_days: number;
	enach_repayment_fee_fields: string;
	id_fee_flat: string;
	matm_fee_flat: string;
	fingerprint_fee_flat: string;
	apr:number;
	enach_charges: string;
	late_charges: string;
	grace_period: string;
	soundbox_upfront_deduction_amount:number
}
