// partner
export {PartnerModel} from './partner/partner.model';
export {DisbursalLimitModel} from './partner/disbursal-limit.model';
export {BankAccountModel} from './partner/bank-account.model';
// product
export {ProductModel} from './products/product.model';
export {ProductVersionModel} from './products/product-version.model';
export {DatumModel} from './products/datum.model';
export {ProductMappingModel} from './products/product-mapping.model';
// export {ApiConfigModel} from './products/api-config.model';
export {TncModel} from './lender-mapping/tnc/tnc.model';
export {LenderModel} from './lender-mapping/lender/lender.model';
export {CommercialModel} from './products/commercial.model';
export {InfoModel} from './products/info.model';
export {CreditModel} from './products/credit.model';
export {DetailModel} from './products/detail.model';
export {FldgModel} from './products/fldg.model';
export {LegacyModel} from './products/legacy.model';
export {LenderMappingModel} from './lender-mapping/lender-mapping.model';
export {RecoveryModel} from './products/recovery.model';
export {SpocModel} from './partner/spoc.model';
export {CustomerModel} from './customer/customer.model';
export {PreQualificationModel} from './customer/pre-qualification.model';
export {LoanModel} from './loan/loan.model';
export {LoanRequestModel} from './loan/loan-request.model';
export {LoanActivityModel} from './loan/loan-activity.model';
export {LoanRepaymentModel} from './loan/loan-repayment.model';
export {KycCustomerModel} from './customer/kyc-customer.model';
export {FileUploadModel} from './file-upload/file-upload.model';
export {FileViewModel} from './file-upload/file-view.model';
export {AceConfigModel} from './products/ace-config.model';
export {CrifDataRequestModel} from './crif/crif-data-request.model';
export {CrifDatumModel} from './crif/crif-datum.model';
export {ReconModel} from './recon/recon.model';
export {PngTableConfigModel} from './miscellaneous/png-table-config.model';
export {SMTConfigModel} from './miscellaneous/s-m-t-config.model';
export {ElasticSearchQueryModel} from './miscellaneous/elastic-search-query.model';
export {UserModel} from './users/user.model';
export {LoginModel} from './users/login.model';
export {PermissionGroupModel} from './users/permission-group.model';
export {PermissionModel} from './users/permission.model';
