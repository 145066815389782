import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "./../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class FileUploadService {
	// tslint:disable-next-line:max-line-length
	// private esBaseUrl = environment.urls.elastic.base;
	private esBaseUrl = environment.urls.elastic.lambda;
	private apiBaseUrl = environment.urls.api.base;
	private oldBaseUrl = environment.urls.api.old_url;

	constructor(private http: HttpClient) {}

	postFileUpload(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + "admin/uploads", postData, this.esHeaders());
	}

	partnerFileUpload(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + "partners/file_uplaod", postData, this.esHeaders());
	}

	paymintDeviceDataUpload(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + "admin/uploads", postData, this.esHeaders());
	}

	sampleFile(fileType: string): any {
		const url = `${this.oldBaseUrl}admin/sample_downloads?file_type=${fileType}`;
		//Add authentication headers as params
		var params = {
			access_token: "An access_token",
			other_header: "other_header",
		};

		//Add authentication headers in URL
		// var newUrl = ["url_generating_pdf", param(params)].join('?');
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		let accessToken: any = localStorage.getItem("accessToken");
		if (accessToken) {
			headers = headers.set("Token", `Bearer ${accessToken}`);
			headers = headers.set("content-type", "text/csv");
		}
		// console.log('accessToken', accessToken);
		console.log("url", url);
		try {
			return this.http.get(url, { headers, responseType: "blob" });
		} catch (e) {
			console.log(e);
			return e;
		}
	}
	getUploadData(searchText, size, pageNumber): Observable<any> {
		let body = {
			search_text: searchText,
			per_page: size,
			page_number: pageNumber,
		};

		return this.http.post(this.apiBaseUrl + "/admin/uploads/list", body, this.esHeaders());
	}
	getPartnersUploadData(searchText, size, pageNumber): Observable<any> {
		let body = {
			search_text: searchText,
			per_page: size,
			page_number: pageNumber,
		};
		/* 	return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.partner_file_uploads + '/_search?' +
			'&size=10000' +
			'&filter_path=hits.hits._source.id,hits.hits._source.uid,hits.hits._source.status,hits.hits._source.upload_type,hits.hits._source.date,hits.hits._source.file_name,hits.hits._source.t,hits.hits._source.updated_at,hits.hits._source.partner_uid,hits.hits._source.uploaded_at,hits.total.value' +
			'&sort=t:desc'
		); */
		return this.http.post(this.apiBaseUrl + "portal_upload_list", body, this.esHeaders());
	}

	getPartnerUploadData(partnerUid: string): Observable<any> {
		/* return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.partner_file_uploads + '/_search?' +
			'&size=10000' +
			'&filter_path=hits.hits._source.id,hits.hits._source.uid,hits.hits._source.status,hits.hits._source.upload_type,hits.hits._source.date,hits.hits._source.file_name,hits.hits._source.t,hits.hits._source.updated_at,hits.hits._source.partner_uid,hits.hits._source.uploaded_at,hits.total.value' +
			'&sort=t:desc'+
			'&q=partner_uid:' + partnerUid
		); */
		let body = {
			index: environment.urls.elastic.indices.partner_file_uploads,
			body: {
				query: {
					//match_phrase: { partner_uid: partnerUid },
					bool: {
						must: [],
					},
				},
				sort: [],
				aggs: {
					total_count: {
						value_count: {
							field: "_id",
						},
					},
				},
				user: {},
			},
			_source: ["id", "uid", "status", "upload_type", "date", "file_name", "t", "updated_at", "partner_uid", "uploaded_at", "total.value"],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ["t:desc"],
		};
		return this.http.post(this.esBaseUrl + "search", body, this.esHeaders());
	}

	getPartnerUploadFile(UploadId: string): Observable<any> {
		let headers = new HttpHeaders();
		let accessToken: any = localStorage.getItem("accessToken");
		if (accessToken) {
			console.log("getPartnerUploadFile", accessToken);
			headers = headers.set("token", `Bearer ${accessToken}`);
		}
		const httpOptions = {
			headers: headers,
		};
		return this.http.get(this.apiBaseUrl + "partners/file_uplaod/" + UploadId, this.esHeaders());
	}

	getUploadFile(UploadId: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + "admin/uploads/" + UploadId, this.esHeaders());
	}

	esHeaders() {
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		let accessToken: any = localStorage.getItem("accessToken");
		if (accessToken) {
			headers = headers.set("Token", `Bearer ${accessToken}`);
		}
		return { headers };
	}
}
