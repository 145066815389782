import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, UserService } from '../../../../core/_services';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { sha256 } from 'js-sha256';

const DEMO_PARAMS = {
	PASSWORD: ''
};


@Component({
	selector: 'kt-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

	changePasswordForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private sessionParam: any;
	private userNameParam: any;

	private returnUrl: any;


	constructor(
		private router: Router,
		private userService: UserService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private permissionsService: NgxPermissionsService,
		private toastr: ToastrService) { }

	ngOnInit() {

		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
			this.userNameParam = params.userName;
			this.sessionParam = params.session;
		});
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.loading = false;
	}

	initLoginForm() {
		// demo message to show
		const initialNotice = `<strong>Enter new password ${DEMO_PARAMS.PASSWORD}</strong> to change your password and continue.`;
		this.authNoticeService.setNotice(initialNotice, 'info');

		this.changePasswordForm = this.fb.group({
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	submit() {
		const controls = this.changePasswordForm.controls;

		if (this.changePasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			userName: this.userNameParam,
			// password: controls.password.value,
			password: sha256(controls.password.value),
			session: this.sessionParam,
		};

		this.userService.respondToAuthChallenge(authData).subscribe(data => {
			console.log(data, 'data');
			if (data.AuthenticationResult.AccessToken) {
				this.toastr.success('Password Reset Successful', 'Success');
				localStorage.setItem('accessToken', data.AuthenticationResult.AccessToken);
				console.log(this.userNameParam);
				this.getUserInfo(this.userNameParam);
			}
		}, error => {
			if (error && error.error) {
				if (error.error.code === 'NotAuthorizedException') {
					this.toastr.error(error.error.message, 'Error');
					this.loading = false;
					delay(2000);
					this.router.navigate(['/auth/login']);
				} else {
					this.authNoticeService.setNotice(error.error.message, 'danger');
					this.loading = false;
				}
			} else {
				this.toastr.error('Please contact your system administrator.', 'Error');
				this.loading = false;
				delay(2000);
				this.router.navigate(['/auth/login']);
			}
		});

	}


	getUserInfo(userName) {
		this.userService.getUser({ userName }).subscribe(data => {
			console.log(data, 'getUserInfo -data');
			localStorage.setItem('user', JSON.stringify(data));
			this.getRolePermissions(data.role);
		}, error => {
			console.log(error, 'error');
		});
	}

	getRolePermissions(roleId) {
		this.userService.getRole(roleId).subscribe(data => {
			console.log(data, 'getRolePermissions -data');
			localStorage.setItem('permissions', JSON.stringify(data.permissions));
			this.permissionsService.loadPermissions(data.permissions);
			this.permissionsService.addPermission('LOGGED_IN');
			this.router.navigateByUrl(this.returnUrl);
		}, error => {
			console.log(error, 'error');
		});
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.changePasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
