import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class WelcomeCallService {
	public appliedFromList = [
		"Partner Portal",
		"Partner Mobile App",
		"ARTH App",
		"Not Willing To Answer"
	];

	public loanPurposeList = [
		"Business- Shop Repair /Improvement",
		"Business- Stock purchase",
		"Business- New Set up",
		"Business- Material & Machinery",
		"Business- Working Capital",
		"Business - Recovery",
		"Personal Usage",
		"Other Investment",
		"Not Willing To Answer"
	];

	public bussinessTypeList = [
		"Seller - Retailer",
		"Seller - Wholesale",
		"Seller - Hawker",
		"Seller - Food & Beverages",
		"Service - Wellness",
		"Service",
		"Agri-Farming",
		"Agri-Non-Farming",
		"Not Willing To Answer",
		"Not Valid For This Call"
	];

	public bussinessEnvironmentLookUpList = [
		{
			name: "Seller - Retailer",
			bussinessEnvironmentInnerList: [
				"Retail Shop",
				"Retail Stall",
				"Retail Outlet",
				"Open Market Retailing",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Seller - Wholesale",
			bussinessEnvironmentInnerList: [
				"Store/Warehouse - Wholesale",
				"Not Valid For This Call"
			]
		},
		{
			name: "Seller - Hawker",
			bussinessEnvironmentInnerList: [
				"Open Market Hawker",
				"Hawker Stall",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Seller - Food & Beverages",
			bussinessEnvironmentInnerList: ["F&B Shop", "Not Valid For This Call"]
		},
		{
			name: "Service - Wellness",
			bussinessEnvironmentInnerList: [
				"Wellness Shop",
				"Open Market Wellness",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Service",
			bussinessEnvironmentInnerList: [
				"Service Shop",
				"Service Store/Warehouse",
				"Open Market Service",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Agri-Farming",
			bussinessEnvironmentInnerList: [
				"Land Agri Farming",
				"Store/Warehouse Agri Farming",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Agri-Non-Farming",
			bussinessEnvironmentInnerList: [
				"Shop Agri-Non-Farming",
				"Open Market Agri-Non-Farming",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Not Willing To Answer",
			bussinessEnvironmentInnerList: ["Not Willing To Answer"]
		},
		{
			name: "Not Valid For This Call",
			bussinessEnvironmentInnerList: ["Not Valid For This Call"]
		}
	];

	public bussinessOccupationLookUpList = [
		{
			name: "Retail Shop",
			bussinessOccupationInnerList: [
				"General Store",
				"Utensils Store",
				"Food - Sweets",
				"Garments",
				"Fruits",
				"Vegetables",
				"Pharmacy",
				"Clay/Pottery",
				"Fresh Snacks",
				"Incense Stick",
				"Mini Bites",
				"Paint & Building Material",
				"Electronics",
				"Saviouries (Namkeen)",
				"Jeweller",
				"Book Store/Stationary",
				"Printing Press",
				"Readymade Cloth",
				"Gifts & HomeDecor",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Retail Stall",
			bussinessOccupationInnerList: [
				"Food - Sweets",
				"Fresh Snacks",
				"Mini Bites",
				"Fruits",
				"Vegetables",
				"Clay/Pottery",
				"Gifts & HomeDecor",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Retail Outlet",
			bussinessOccupationInnerList: [
				"Garments",
				"Readymade Cloth",
				"Paint & Building Material",
				"Electronics",
				"Jeweller",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Open Market Retailing",
			bussinessOccupationInnerList: [
				"Fruits",
				"Vegetables",
				"Clay/Pottery",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Not Willing To Answer",
			bussinessOccupationInnerList: ["Not Willing To Answer"]
		},
		{
			name: "Not Valid For This Call",
			bussinessOccupationInnerList: ["Not Valid For This Call"]
		},
		{
			name: "Store/Warehouse - Wholesale",
			bussinessOccupationInnerList: [
				"Garments",
				"Readymade Cloth",
				"Paint & Building Material",
				"Electronics",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Open Market Hawker",
			bussinessOccupationInnerList: [
				"Fruits",
				"Vegetables",
				"Clay/Pottery",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Hawker Stall",
			bussinessOccupationInnerList: [
				"Fruits",
				"Vegetables",
				"Clay/Pottery",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "F&B Shop",
			bussinessOccupationInnerList: [
				"Fresh Snacks",
				"Fruits",
				"Food - Sweets",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Wellness Shop",
			bussinessOccupationInnerList: [
				"Diagnostics",
				"Pharmacy",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Open Market Wellness",
			bussinessOccupationInnerList: [
				"Nursing",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Service Shop",
			bussinessOccupationInnerList: [
				"Mobile Repair",
				"General Repair",
				"Govt - CSP",
				"Lodge",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Service Store/Warehouse",
			bussinessOccupationInnerList: [
				"Catering",
				"Events (Tent)",
				"Utilities (gas)",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Open Market Service",
			bussinessOccupationInnerList: [
				"E Rickshaw",
				"Teacher",
				"Transport",
				"Tailoring",
				"Construction",
				"Payment Service Provider (DMT, AEPS, Ticketing)",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Open Market Service",
			bussinessOccupationInnerList: [
				"E Rickshaw",
				"Teacher",
				"Transport",
				"Tailoring",
				"Construction",
				"Payment Service Provider (DMT, AEPS, Ticketing)",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Land Agri Farming",
			bussinessOccupationInnerList: [
				"Vegetables",
				"Fruits",
				"Seasonal Crops",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Store/Warehouse Agri Farming",
			bussinessOccupationInnerList: [
				"Dairy",
				"Poultry",
				"Fishery",
				"Meat",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Shop Agri-Non-Farming",
			bussinessOccupationInnerList: [
				"General Repair",
				"Tailoring",
				"Small scale Manufacturing",
				"General Store",
				"Blacksmiths",
				"Carpentry",
				"Pottery",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Open Market Agri-Non-Farming",
			bussinessOccupationInnerList: [
				"Teacher",
				"Construction",
				"Transport",
				"E Rickshaw",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		},
		{
			name: "Open Market Agri-Non-Farming",
			bussinessOccupationInnerList: [
				"Teacher",
				"Construction",
				"Transport",
				"E Rickshaw",
				"Others",
				"Not Willing To Answer",
				"Not Valid For This Call"
			]
		}
	];
}
