export class CrifDataRequestModel {
	partner_code: string;
	customer_code: string;
	loan_uid: string;
	status: string;
	requested_at: string;
	responded_at: string;
	dob: string;
	valid_till: string;
	pan: string;
	mobile: string;
	pincode: string;
	city: string;
	state: string;
	address: string;
	batch_uid: string;
	customer_name: string;
	report_url: string;
	report_overdue_record_present: boolean;
	report_score: string;
	report_identifier: string;
	report_uniq_ref_number: string;
	t: number;
	pdf_data: Text;
	html_data: Text;
	s3_url: string;
}
