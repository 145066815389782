// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {NgxPermissionsGuard} from 'ngx-permissions';
import {AuthGuard} from './core/_guards/auth.guard';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		canActivateChild: [NgxPermissionsGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'admin',
				loadChildren: () => import('./views/pages/admin/admin.module').then(m => m.AdminModule)
			},
			{
				path: 'customer',
				loadChildren: () => import('./views/pages/customer/customer.module').then(m => m.CustomerModule),
				data: {roles: ['customer']}
			},
			{
				path: 'loan',
				loadChildren: () => import('./views/pages/loan/loan.module').then(m => m.LoanModule)
			},
			{
				path: 'report',
				loadChildren: () => import('./views/pages/report/report.module').then(m => m.ReportModule)
			},
			{
				path: 'builder',
				loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: '/dashboard', pathMatch: 'full'}
		]
	},
	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}

