import {BankAccountModel} from './bank-account.model';

export class PartnerModel {
	id?: number;
	code: string;
	uid: string;
	name: string;
	locked_at: string;
	slug: string;
	settlement_frequency: number;
	category: string;
	created_at: string;
	updated_at: string;
	registered_name: string;
	nda_url: any={};
	mou_url: any={};
	agreement_url:any={};
	headquarter: string;
	established_at: string;
	business_line: string;
	website: string;
	total_merchants: number;
	active_merchants: number;
	status: string;
	txn_type: string;
	hold_repayment_sync: boolean;
	logo: any={};
	bank_accounts?: BankAccountModel[];
}
