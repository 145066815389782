export class FileUploadModel {
    fileData: string;
    fileDate: string;
    fileType: string;
    fileName: string;
    partnerCode: string;
    organizationId: string;
    name: string;
    email: string;
    role: string;
}

