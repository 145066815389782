import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import { AuthService } from '../../views/pages/auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private permissionsService: NgxPermissionsService, private router: Router, private authService: AuthService) {
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// this.permissionsService.getPermissions()
		if (this.permissionsService.getPermissions().LOGGED_IN) {
			return true;
		} else {
			/* if (localStorage.getItem('permissions')) {
				this.permissionsService.flushPermissions();
				this.permissionsService.addPermission(JSON.parse(localStorage.getItem('permissions')));
				this.permissionsService.addPermission('LOGGED_IN');
				return true;
			} else {
				this.router.navigate(['/auth/login']);
				return false;
			} */
			/// console.log('this.authService.pemissions', this.authService.permissions);
			if (this.authService.permissions) {
				this.permissionsService.flushPermissions();
				this.permissionsService.addPermission(this.authService.permissions);
				this.permissionsService.addPermission('LOGGED_IN');
				return true;
			} else {
				this.router.navigate(['/auth/login']);
				return false;
			}
		}
	}

}
