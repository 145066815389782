import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class CkycService {
	private apiBaseUrl = environment.urls.api.base;

	constructor(private http: HttpClient) {
	}

    getData():Observable<any> {
        return this.http.get(this.apiBaseUrl +'ckyc_reports',this.esHeaders())
    }

	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
		let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		return {headers};
	}
}