/* tslint:disable:indent */
// tslint:disable
import { Injectable } from '@angular/core';
import { AuthNoticeService, UserService } from '../../../core/_services';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { interval } from 'rxjs';
@Injectable({
	providedIn: 'root'
})


export class AuthService {
	private returnUrl: any = '/';
	private Permissions: any;
	private _user: any;
	private Token: string;
	private readonly REFRESH_TOKEN: string = 'refreshToken';

	constructor(
		private http: HttpClient,
		private userService: UserService,
		private permissionsService: NgxPermissionsService,
		// private router: Router
	) { }

	getUserInfo(userName) {
		this.userService.getUser({ userName }).subscribe(data => {
			console.log(data, 'getUserInfo -data');
			// localStorage.setItem('user', JSON.stringify(data));
			this._user = data;
			this.getRolePermissions(data.role);
		}, error => {
			console.log(error, 'error');
		});
	}

	getRolePermissions(roleId) {
		localStorage.setItem('role', roleId)
		this.userService.getRole(roleId).subscribe(data => {
			console.log(data, 'getRolePermissions -data');
			// localStorage.setItem('permissions', JSON.stringify(data.permissions));
			this.Permissions = data.permissions;
			this.permissionsService.loadPermissions(data.permissions);
			this.permissionsService.addPermission('LOGGED_IN');
			this.startTokenUpdateTimer(1);
			// this.router.navigateByUrl(this.returnUrl);
		}, error => {
			console.log(error, 'error');
		});
	}
	public getUserInfoANDRolePermissions({ userName }): Observable<any> {
		return this.userService.getUser({ userName }).pipe(
			switchMap(data => {
				this._user = data;
				return this.userService.getRole(data.role);
			})
		);
	}

	public setPermissions(data: any) {
		this.Permissions = data.permissions;
		this.permissionsService.loadPermissions(data.permissions);
		this.permissionsService.addPermission('LOGGED_IN');
	}
	public setTokens(data) {
		// console.log('setTokens == > ', data);
		const refreshToken = data.RefreshToken;
		// localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
		// console.log('setTokens data', data);
		this.Token = data.IdToken;
		// console.log(' this.Token == > ', this.Token);
	}

	public get user() {
		// console.log('auth service get user ',this._user);
		return this._user;
	}

	public set user(value: any) {
		// console.log('auth service set user ',value);
		this._user = value;
	}

	public get permissions() {
		return this.Permissions;
	}

	public set permissions(value: any) {
		this.Permissions = value;
	}

	public get token() {
		return this.Token;
	}

	public refreshToken(minutes: number) {
		return new Observable<any>(
			subscriber => {
				interval(1000 * 60 * minutes).subscribe(x => {
					const refreshToken = localStorage.getItem(this.REFRESH_TOKEN);
					// console.log('refresh_tok en ==', refreshToken);
					this.userService.getTokens(refreshToken).subscribe(response => {
						subscriber.next(response);
					});
				});
			}
		);
	}

	public startTokenUpdateTimer(time: number) {
		this.refreshToken(time).subscribe(data => {
			const token = {
                IdToken: data.id_token,
                RefreshToken: data.RefreshToken
            }
			// console.log('startTokenUpdateTimer', token);
			this.setTokens(token);
		});
	}

}
