import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LenderMappingService, PartnerService, ProductService} from '../../../../core/_services';
import {LazyLoadEvent} from 'primeng/api';
import {environment} from '../../../../../environments/environment';
import * as elasticsearch from 'elasticsearch-browser';
import {UtilsService} from '../../../../core/_global/utils.service';
import {PngTableConfigColTypeEnum} from '../../../../core/_enum';
import { AuthService } from '../../../pages/auth/auth.service';

@Component({
	selector: 'kt-png-table',
	templateUrl: './png-table.component.html',
	styleUrls: ['./png-table.component.scss']
})
export class PngTableComponent implements OnInit {
	@Input() config: any = {};
	colTypeEnum = PngTableConfigColTypeEnum;

	esClient: elasticsearch.Client;
	dataList: Array<any> = [];
	partnersCodeList = [];
	partnersHash: any[] = [];
	productsHash: any[] = [];
	lenderList = [];
	lenderHash: any[] = [];
	loanStatusList = [];


	cols: any[] = [];
	globalSearchFeilds: any[] = [];
	globalSearchFeildsDisplay: any[] = [];
	loading = false;
	totalRecords = 0;
	totalDisplayRecords = 0;


	queryBody = {
		index: null,
		type: '',
		body: {
			query: {bool: {must: []}},
			sort: [],
			_source: [],
			aggs: {
				total_count: {value_count: {field: '_id'}}
			}
		},
		// scroll: '1m',
		size: 10,
		from: 0,
		filterPath: [
			'aggregations.total_count.value', 'hits.total.value', 'hits.hits._source', 'hits.hits._id'
		]
	};
	showFilters: false;

	constructor(
		private utils: UtilsService,
		private changeDetectorRefs: ChangeDetectorRef,
		private partnerService: PartnerService,
		private productService: ProductService,
		private lenderMappingService: LenderMappingService,
		private authService: AuthService
	) {
		if (!this.esClient) {
			this.esClient = new elasticsearch.Client({
				host: environment.urls.elastic.base
			});
		}
	}

	ngOnInit() {
		this.parseConfig();
	}

	parseConfig() {
		this.cols = this.config.cols;
		this.cols.forEach(item => {
			if (item.globalSearch) {
				this.globalSearchFeilds.push(item.field);
				this.globalSearchFeildsDisplay.push(item.header);
			}
			switch (item.type) {
				case this.colTypeEnum.PARTNER_CODE :
				case this.colTypeEnum.PARTNER_UID : {
					this.getPartnersHash();
					break;
				}
				case this.colTypeEnum.LENDER_CODE : {
					this.getLendersHash();
					break;
				}
				case this.colTypeEnum.LOAN_STATUS : {
					this.getLoanStatusList();
					break;
				}
				case this.colTypeEnum.PRODUCT_UID : {
					this.getProductHash();
					break;
				}
			}
		});
		console.log(this.globalSearchFeilds);
	}
	restrictSpecialChars(event){
		const NO_SPECIAL_CHAR = /^[a-zA-Z0-9. ]+$/;
		const value = event.key.toString();
          const emailRegex = new RegExp(NO_SPECIAL_CHAR);
          return !(value && !emailRegex.test(value));
	}

	getDisplayText(row, col) {
		let displayText: string = this.utils.getValFromPath(row, col.field);
		switch (col.type) {
			case this.colTypeEnum.DATE_RANGE:
			case this.colTypeEnum.DATE : {
				displayText = this.utils.convertDateFormat(displayText, 'DD-MMM-YYYY');
				break;
			}
			case this.colTypeEnum.TIME: {
				displayText = this.utils.convertDateFormat(displayText, 'h:mm a');
				break;
			}
			case this.colTypeEnum.MOBILE: {
				displayText = '******' + displayText;
				break;
			}
			case this.colTypeEnum.LENDER_UID: {
				displayText = displayText;
				break;
			}
			case this.colTypeEnum.PARTNER_UID: {
				displayText = this.partnersHash[displayText];
				break;
			}
			case this.colTypeEnum.PRODUCT_UID: {
				displayText = this.productsHash[displayText];
				break;
			}
		}
		return displayText;
	}

	getLendersHash() {
		this.lenderMappingService.getLendersName().subscribe(
			response => {
				const lendersList = response.data;
				lendersList.reduce((dict, val) => {
					dict[val.code] = val.uid;
					return dict;
				}, this.lenderHash);
				console.log(this.lenderHash);
			}
		);
	}

	getPartnersHash() {
		this.partnerService.getPartnerNames().subscribe(
			response => {
				const partnersList = response.data;
				partnersList.reduce((dict, val) => {
					dict[val.uid] = val.code;
					return dict;
				}, this.partnersHash);
				this.partnersCodeList = partnersList.map(item => item.code);
				console.log(this.lenderHash);
			}
		);
	}

	getProductHash() {
		this.productService.getProducts().subscribe(
			response => {
				const productsList = response.data;
				productsList.reduce((dict, val) => {
					dict[val.uid] = val.code;
					return dict;
				}, this.productsHash);
				console.log(this.productsHash);
			}
		);
	}

	getLoanStatusList() {
		this.loanStatusList = this.utils.getLoanStatusList();
	}

	getData(event: LazyLoadEvent) {
		// in a real application, make a remote request to load data using state metadata from event
		// event.first = First row offset
		// event.rows = Number of rows per page
		// event.sortField = Field name to sort with
		// event.sortOrder = Sort order as number, 1 for asc and -1 for dec
		// filters: FilterMetadata object having field as key and filter value, filter matchMode as value

		// imitate db connection over a network

		this.addUserFilter();
		console.log(event.filters);

		this.loading = true;
		this.getDataFromEs(event).then(response => {
			this.totalRecords = 0;
			this.totalDisplayRecords = 0;
			this.dataList = [];
			if (response.data) {
				for (const item of response.data) {
					// tslint:disable-next-line:max-line-length
					item.mobile = item.mobile ? item.mobile.substr(item.mobile.length - 4, item.mobile.length) : '';
					this.dataList.push(item);
				}
				this.totalRecords = response.aggregations.total_count.value;
				this.totalDisplayRecords = response.data;
			}
			console.log(this.dataList);
			this.loading = false;
			this.changeDetectorRefs.detectChanges();
		});
	}

	getDataFromEs(event: LazyLoadEvent): Promise<any> {
		console.log(event);
		this.queryBody.index = this.config.index;
		this.queryBody.body._source = [];
		this.queryBody.size = event.rows;
		this.queryBody.from = event.first;
		this.queryBody.body.query.bool.must = [];
		this.queryBody.body.sort = [];
		let user = JSON.parse(localStorage.getItem("user"));

		this.config.cols.forEach(item => this.queryBody.body._source.push(item.field));

		this.cols.forEach(item => {
			if (event.filters[item.field]) {
				switch (item.type) {
					case this.colTypeEnum.DATE_RANGE : {
						this.queryBody.body.query.bool.must.push(
							{
								range: {
									[item.field]: {
										gte: this.utils.convertDateFormat(event.filters[item.field].value.begin, 'YYYY-MM-DD'),
										lte: this.utils.convertDateFormat(event.filters[item.field].value.end, 'YYYY-MM-DD')
									}
								}
							}
						);
						break;
					}
					case this.colTypeEnum.LOAN_STATUS :
					case this.colTypeEnum.PARTNER_UID :
					case this.colTypeEnum.PRODUCT_CODE :
					case this.colTypeEnum.PARTNER_CODE :
					case this.colTypeEnum.LENDER_CODE :
					case this.colTypeEnum.PRODUCT_UID :
					case this.colTypeEnum.LENDER_UID : {
						this.pushMustMatch(item.field, event.filters[item.field].value);
						break;
					}
					case this.colTypeEnum.TEXT : {
						this.queryBody.body.query.bool.must.push(
							{wildcard: {[item.field]: '*' + event.filters[item.field].value.toLowerCase() + '*'}}
						);
						break;
					}
				}
			}
		});

		if (event.filters.global) {
			const globalSearchQuery = [];

			this.config.cols.forEach(item => {
				if (item.globalSearch) {
					const wildcardSearch = (item.type === this.colTypeEnum.MOBILE) ? '??????' + event.filters.global.value + '*' : '*' + event.filters.global.value.toLowerCase() + '*';
					globalSearchQuery.push({wildcard: {[item.field]: wildcardSearch}});
				}
			});

			this.queryBody.body.query.bool.must.push({bool: {should: globalSearchQuery}});
		}

		if (event.sortField) {
			this.queryBody.body.sort.push({[event.sortField]: {order: event.sortOrder === 1 ? 'asc' : 'desc'}});
		} else {
			this.queryBody.body.sort.push({['t']: {order: 'desc'}});
		}

		console.log(this.queryBody);

		this.addUserFilter();

		return this.esClient.search(this.queryBody);
	}

	addUserFilter() {
		console.log("Adding user filter");
		let user = this.authService.user; // JSON.parse(localStorage.getItem("user"));
		this.cols.forEach(item => {
			if (this.utils.userIsLender()) {
				switch (item.type) {
					case this.colTypeEnum.LENDER_CODE : {
						this.pushMustMatch(item.field, user.org);
					}
				}
			}
			else if (this.utils.userIsPartner()) {
				switch (item.type) {
					case this.colTypeEnum.PARTNER_CODE : {
						this.pushMustMatch(item.field, user.org);
					}
				}
			}
		});
	}

	pushMustMatch(key, value) {
		let replaced = false;
		this.queryBody.body.query.bool.must.forEach(must => {
			if (must["match"] != null && must["match"][key] != null) {
				replaced = true;
				must["match"][key] = value
			}
		});
		if (!replaced) {
			this.queryBody.body.query.bool.must.push(
				{match: {[key]: value}}
			);	
		}
	}
}

