import { TncModel } from './tnc/tnc.model';
import { KycPolicyModel } from '../products/kyc-policy.model';
import { LenderModel } from './lender/lender.model';
import {ProductVersionModel} from '../products/product-version.model';
import { from } from 'rxjs';

export class LenderMappingModel {
	id: number;
	name: string;
	lender_id: number;
	tnc_id: number;
	kyc_policy_id: number;
	created_at: string;
	updated_at: string;
	kyc_policy: KycPolicyModel;
	lender: LenderModel;
	tnc: TncModel;
	product_versions: ProductVersionModel[];
	product_version_ids?: number[];
}
