import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
 
@Injectable({
	providedIn: 'root'
})
 
export class SignupService {

    API_CREATE_LEADS:string ='/v1/lead/create_leads';
    apiSdkUrl = environment.urls.api.sdk_api;

    constructor(private http: HttpClient) {
	}


    private partnerData = new BehaviorSubject<any>(null);
    partnerData$ = this.partnerData.asObservable();
    setpartnerData(event: string) 
    {
        this.partnerData.next(event);
    }

    createLeads(params: any): Observable<any> {
		return this.http.post(`${this.apiSdkUrl}${this.API_CREATE_LEADS}`, params);
	}
}