export { BaseService } from './base.service';
export { PartnerService } from './partner.service';
export { ProductService } from './product.service';
export { LoanService } from './loan.service';
export { CkycService } from './ckyc.service';
export { LenderMappingService}  from './lender-mapping.service';
export { CustomerService } from './customer.service';
export { FileUploadService } from './file-upload.service';
export { ReportService } from './report.service';
export { ElasticSearchService } from './elastic-search.service';
export { UserService } from './user.service';
export { AuthNoticeService } from './auth-notice/auth-notice.service';
export { AuthNotice } from './auth-notice/auth-notice.interface';
export { WelcomeCallService } from './welcome-call.service';
export{ SignupService } from './signup.service';