export class SMTConfigModel {
	title: string;
	index: string;
	allowDownload?: boolean;
	cols: Col[];
	actions?: string[];
	sortKey: string;
	method?: {
		getDisplayText: any
	};
}

class Col {
	field: string;
	display: boolean;
	header?: string;
	globalSearch?: boolean;
	link?: string;
	fieldSearch?: boolean;
	type?: any;
}
