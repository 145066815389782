export class ElasticSearchQueryModel {
	index: string;
	body: Body;
	size: number;
	from: number;
	filterPath: string[];
	sort: string[];
	_source: any[];
}

class Body {
	query: Query;
	sort?: any[];
	aggs: Aggs;
	user: any;
}


class Query {
	bool: Bool;
}
	
class Bool {
	must: any[];
	should:any[];
}


class ValueCount {
	field: string;
}

class TotalCount {
	value_count: ValueCount;
}

class Aggs {
	total_count: TotalCount;
}
