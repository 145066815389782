import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CustomerService {
	// tslint:disable-next-line:max-line-length
	// private esBaseUrl = environment.urls.elastic.base;
	private esBaseUrl = environment.urls.elastic.lambda;
	private apiBaseUrl = environment.urls.api.base;
	private oldUrl=environment.urls.api.old_url

	constructor(private http: HttpClient) {
	}

	getCustomer(applicantId: any): Observable<any> {
	/* 	return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.customers + '/_search?' +
			'size=10000&' +
			'filter_path=hits.hits._source.*' +
			'&q=applicant_id:' + applicantId
		); */
	return this.http.get(this.oldUrl+`elastic/customers/details?applicant_id=${applicantId}`,this.esHeaders())
	}

	getAdminList(url,search_text,filter,page): Observable<any>{
		let body = {
			"search_text": search_text,
			"filters":filter,
			"page_number": page.page_number,
			"per_page": page.per_page
		}
		return this.http.post(this.apiBaseUrl + url, body, this.esHeaders());

	}

	getInfoCustomerByCustomerCode(customerCode: any): Observable<any> {
	/* 	return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.customers + '/_search?' +
			'size=10000&' +
			'filter_path=hits.hits._source.*' +
			'&q=customer_code:' + customerCode
		); */
		let body = {
			index: environment.urls.elastic.indices.customers,
			body: {
			  query: {
				match_phrase: { customer_code: customerCode },
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: 'customerCode',
				  },
				},
			  },
			  user: {},
			},
			_source: ['*'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: [],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	getInfoCustomerByApplicantId(applicantID: any): Observable<any> {
		/* return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.customers + '/_search?' +
			'size=10000&' +
			'filter_path=hits.hits._source.*' +
			'&q=applicant_id:' + applicantID
		); */
		let body = {
			index: environment.urls.elastic.indices.customers,
			body: {
			  query: {
				match_phrase: { applicant_id: applicantID },
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: ['*'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: [],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	getKycCustomer(partnerCode: string, customerCode: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + 'kycs/' + partnerCode + '/' + customerCode, this.esHeaders());
	}


	getCustomers(): Observable<any> {
		/* return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.customers + '/_search?' +
			'&size=10000' +
			'&filter_path=hits.hits._source.id,hits.hits._source.customer_uid,hits.hits._source.name,hits.hits._source.email,hits.hits._source.mobile,hits.hits._source.city,hits.hits._source.partner_name,hits.hits._source.city,hits.hits._source.joining_date,hits.hits._source.created_at,hits.total.value' +
			'&sort=t:desc'
		); */
		let body = {
			index: environment.urls.elastic.indices.customers,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: [
			  'id',
			  'customer_uid',
			  'name',
			  'email',
			  'mobile',
			  'city',
			  'partner_name',
			  'city',
			  'joining_date',
			  'created_at',
			  'total.value',
			],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	getPreQualificationByCustomer(id): Observable<any> {
	/* 	return this.http.get(
			this.esBaseUrl + environment.urls.elastic.indices.prequalifications + '/_search?' +
			'size=10000' +
			'&filter_path=hits.total.value,hits.hits._source.t,hits.hits._source.id,hits.hits._source.customer_code,hits.hits._source.cohort_uid,hits.hits._source.generated_at,hits.hits._source.status,hits.hits._source.tenure,hits.hits._source.amount_offered,hits.hits._source.failure_reason' +
			'&sort=t:desc' +
			'&q=applicant_id:' + id
		); */
		let body = {
			index: environment.urls.elastic.indices.prequalifications,
			body: {
			  query: {
				match_phrase: { applicant_id: id },
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: [
			  't',
			  'id',
			  'customer_code',
			  'cohort_uid',
			  'generated_at',
			  'status',
			  'tenure',
			  'amount_offered',
			  'failure_reason',
			],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	changeKycStatus(payload): Observable<any> {
		return this.http.patch(this.apiBaseUrl + 'kycs/change_status', payload, this.esHeaders());
	}

	sync(applicant_id: string): Observable<any> {
		return this.http.get(this.apiBaseUrl + 'sync/customer/' + applicant_id, this.esHeaders());
	}

	payeeValidation(applicant_id: string, loan_uid: string, params ){
		return this.http.post(this.apiBaseUrl + 'admin/kycs/' + applicant_id + '/' + loan_uid + '/' + 'payee_validation', params, this.esHeaders());
	}

	getReKycDetails(customer_code: string, partner_code: string): Observable<any> {
		console.log('getReKycDetails this.esHeaders()', this.esHeaders());
		let headers: any = new Headers();
		let accessToken:any = localStorage.getItem('accessToken');
		headers = headers.set('Token', `Bearer ${accessToken}`);
		// console.log('accessToken', accessToken);
		return this.http.get(this.apiBaseUrl + 'kyc-reupload/' + partner_code + '/' + customer_code, this.esHeaders() );
	}

	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
		/*if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		}*/
		let accessToken:any = localStorage.getItem('accessToken');
		// console.log('accessToken', accessToken);
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		return {headers};
	}

	getDisbursalPutOnHold(customerCode: any,data:any): Observable<any> {
			let body = {
				"loan_uid":customerCode,
				"email":data,
				"manual": true
			};
			  return this.http.post(this.apiBaseUrl + 'loans/disburse', body, this.esHeaders());
		}
}
