import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AuthNoticeService, UserService } from '../../../../core/_services';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { sha256 } from 'js-sha256';

const DEMO_PARAMS = {
	CODE: '',
	PASSWORD: ''
};

@Component({
	selector: 'kt-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	// Public params
	resetPasswordForm: FormGroup;
	userNameParam: any;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	constructor(
		public authNoticeService: AuthNoticeService,
		public userService: UserService,
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private toastr: ToastrService
	) {
		this.unsubscribe = new Subject();
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.userNameParam = params.userName;
		});
		this.initRegistrationForm();
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.authNoticeService.setNotice(null);
		this.loading = false;
	}

	initRegistrationForm() {
		const initialNotice = `Enter <strong>verification code ${DEMO_PARAMS.CODE}</strong> along with
		<strong>new password ${DEMO_PARAMS.PASSWORD}</strong> to reset your password.`;
		this.authNoticeService.setNotice(initialNotice, 'info');
		this.resetPasswordForm = this.fb.group({
			code: [DEMO_PARAMS.CODE, Validators.compose([
				Validators.required
			])],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])],


		});
	}

	submit() {
		const controls = this.resetPasswordForm.controls;
		/** check form */
		if (this.resetPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			userName: this.userNameParam,
			// password: controls.password.value,
			password: sha256(controls.password.value),
			confirmationCode: controls.code.value,
		};
		// console.log(authData)

		this.userService.resetPassword(authData).subscribe(data => {
			this.toastr.success('Password Reset Successful', 'Success');
			this.cdr.detectChanges();
			this.router.navigate(['/auth/login']);
		}, error => {
			if (error && error.error) {
				if (error.error.code === 'NotAuthorizedException') {
					this.toastr.error(error.error.message, 'Error');
					this.loading = false;
					delay(2000);
					this.router.navigate(['/auth/login']);
				} else {
					this.authNoticeService.setNotice(error.error.message, 'danger');
					this.loading = false;
				}
			} else {
				this.toastr.error('Please contact your system administrator.', 'Error');
				this.loading = false;
				delay(2000);
				this.router.navigate(['/auth/login']);
			}
		});

	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.resetPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
