import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatCheckboxChange} from '@angular/material';

@Component({
	selector: 'kt-smt-sort-column',
	templateUrl: './smt-sort-column.component.html',
	styleUrls: ['./smt-sort-column.component.scss']
})
export class SmtSortColumnComponent implements OnInit {

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
		private bottomSheetRef: MatBottomSheetRef<SmtSortColumnComponent>
	) {
		console.log(data);
	}

	ngOnInit() {
		this.data.cols.forEach(col => {
			if (this.data.displayedColumns.includes(col.field)) {
				col.checked = true;
			}
		});
	}

	drop(event: CdkDragDrop<string[]>) {
		console.log(event.previousIndex, event.currentIndex);
		moveItemInArray(this.data.cols, event.previousIndex, event.currentIndex);
		this.changeDetectorRef.detectChanges();
		// this.onSave();
	}

	onSave() {
		const result = [];
		this.data.cols.forEach(col => {
			if (col.checked) {
				result.push(col.field);
			}
		});
		console.log(result);
		this.bottomSheetRef.dismiss(result);
	}

	onCheckbox($event: MatCheckboxChange, field) {
		this.data.cols.forEach(col => {
			if (col.field === field) {
				col.checked = $event.checked;
			}
		});
	}
}
