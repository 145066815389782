import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal-alert',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  //   @Input() message: string;
  @Output() close = new EventEmitter<boolean>();
  @Output() continue = new EventEmitter<boolean>();


  onClose() {
    this.close.emit(false);
  }
  onContinue() {
    this.continue.emit(true);
  }
}
