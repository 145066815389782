export class BankAccountModel {
	id: number;
	code: string;
	bank_name: string;
	account_number: string;
	account_holder_name: string;
	ifsc_code: string;
	account_type: string;
	active: boolean;
	account_holder_id: number;
	account_holder_type: string;
}
