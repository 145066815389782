export class DisbursalLimitModel {
	id: number;
	limit_type: string;
	entity_to_check: number;
	maximum_allowed: string;
	time_window: number;
	partner_code: string;
	device_id: string;
	channel: string;
	priority: number;
	description: string;
	notification_threshold: number;
	notification_sms_sent_at: string;
	notification_email_sent_at: string;
	partner_id: number;
	created_at: string;
	updated_at: string;
}
