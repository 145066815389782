export class SpocModel {
	id: number;
	partner_id: number;
	name: string;
	email: string;
	mobile: number;
	department: string;
	login_required: boolean;

	constructor(){
    this.login_required = false;
  }
}