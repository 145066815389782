export class CrifDatumModel {
	id: number;
	valid_till: string;
	score: number;
	query_params: JSON;
	overdue_record_present: boolean;
	s3_bucket: string;
	s3_dir: string;
	data: string;
	created_at: string;
	updated_at: string;
	report_id: string;
	primary_number_of_accounts: number;
	primary_active_accounts: number;
	primary_current_balance: number;
	primary_overdue_number_of_accounts: number;
	primary_disbursed_amount: number;
	total_number_of_accounts: number;
	total_active_accounts: number;
	total_overdue_number_of_accounts: number;
	total_current_balance: number;
	total_disbursed_amount: number;
	inquiries_in_last_six_months: number;
	new_accounts_in_last_six_months: number;
	new_delinq_account_in_last_six_months: number;
	s3_url: string;
	pdf_data: Text;
	html_data: Text;
}
