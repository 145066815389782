import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpClient) {
	}

	login(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'login', params, this.esHeaders());
	}

	samlLogin(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'saml_login', params, this.esHeaders());
	}

	getTokens(refresh_token: string): Observable<any> {
		return this.http.post(environment.urls.api.user + 'getTokens', { refresh_token: refresh_token }, this.esHeaders());
	}

	respondToAuthChallenge(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'respondToAuthChallenge', params, this.esHeaders());
	}

	forgotPasswordEmail(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'resetUserPassword', params, this.esHeaders());
	}

	resetPassword(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'confirmForgotPassword', params, this.esHeaders());
	}

	getUser(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'getUser', params, this.esHeaders() );
	}

	disableUser(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'disableUser', params, this.esHeaders());
	}

	enableUser(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'enableUser', params, this.esHeaders());
	}

	getUsersList(): Observable<any> {
		return this.http.get(environment.urls.api.user + 'listUsers', this.esHeaders());
	}

	postUser(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'createUser', params, this.esHeaders());
	}

	getPermissionsList(): Observable<any> {
		return this.http.get(environment.urls.api.user + 'listPermissions', this.esHeaders());
	}

	getRolesList(): Observable<any> {
		return this.http.get(environment.urls.api.user + 'listRoles', this.esHeaders());
	}

	getRole(id): Observable<any> {
		return this.http.get(environment.urls.api.user + 'getRole?id=' + id, this.esHeaders());
	}

	getRoleById(id): Observable<any> {
		return this.http.get(environment.urls.api.user + 'getRoleById?id=' + id, this.esHeaders());
	}

	postRole(params: any): Observable<any> {
		return this.http.post(environment.urls.api.user + 'postRole', params, this.esHeaders());
	}

	logOut(params): Observable<any> {
		return this.http.post(environment.urls.api.user + 'logout', params , this.esHeaders());
	}
	
	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
	    let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		return {headers};
	}
}
