export class RecoveryModel {
	id: number;
	created_at: string;
	updated_at: string;
    name: string;
	compute_method: string;
	recovery_installment_counts: number;
	recovery_freq: string;
	recovery_days: number;
    start_type: string;
}
