// angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../../environments/environment';

// models

@Injectable({
	providedIn: 'root'
})
export class LenderMappingService {
	// tslint:disable-next-line:max-line-length
	// private esBaseUrl = environment.urls.elastic.base;
	private esBaseUrl = environment.urls.elastic.lambda;
	private apiBaseUrl = environment.urls.api.base;
	private oldBaseUrl = environment.urls.api.old_url
	// need to remove this
	// oldUrl='https://athos-api.staging.arthapp.in/'

	constructor(private http: HttpClient) {
	}

	// getLenderMappings(): Observable<any> {
	// 	// tslint:disable-next-line:max-line-length
	// 	return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.lender_mappings + '/_search?sort=t:desc&size=10000&filter_path=hits.hits._source.*');
	// }

	// getLenderMapping(lenderMappingId: string): Observable<any> {
	// 	// tslint:disable-next-line:max-line-length
	// 	return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.lender_mappings + '/_search?' + 'filter_path=hits.hits._source.*&q=id:' + lenderMappingId);
	// }

	public verifyLender(id: number): Observable<any> {
		return this.http.patch(this.apiBaseUrl + 'lenders/' + id + '/verify', {},this.esHeaders());
	}


	putLenderMapping(postData: any): Observable<any> {
		console.log(postData);
		return this.http.put(this.apiBaseUrl + 'lender_mappings/' + postData.id, postData,this.esHeaders());
	}

	postLenderMapping(postData: any): Observable<any> {
		console.log(postData);
		return this.http.post(this.apiBaseUrl + 'lender_mappings', postData);
	}

	// Tnc
	public getTncs(search_text,pageNumber,perPage): Observable<any> {
		let searchParams= new HttpParams();
		searchParams= searchParams.append('search_text',search_text)
		searchParams= searchParams.append('page_number',pageNumber)
		searchParams= searchParams.append('per_page',perPage)
		let accessToken:any = localStorage.getItem('accessToken');
		return this.http.get(this.oldBaseUrl+`tncs`,{headers:new HttpHeaders({'Token': `Bearer ${accessToken}`}),params:searchParams})
	}

	getTnc(tncId: any): Observable<any> {
		return this.http.get(this.apiBaseUrl+`tncs/${tncId}`,this.esHeaders())
	}

	public postTnc(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'tncs', postData , this.esHeaders());
	}


	public putTnc(postData: any): Observable<any> {
		return this.http.put(this.apiBaseUrl + 'tncs/' + postData.id, postData, this.esHeaders());
	}

	public verifyTnc(id: number): Observable<any> {
		return this.http.patch(this.apiBaseUrl + 'tncs/' + id + '/verify', {}, this.esHeaders());
	}

	// Lender
	getLenderDetails(lenderId: any): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.lenders + '/_search?' + 'size=10000&filter_path=hits.hits._source.*&q=id:' + lenderId);
		return this.http.get(this.apiBaseUrl+`/lenders/${lenderId}`,this.esHeaders())
	}

	getLendersName(): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.lenders + '/_search?size=10000&filter_path=hits.hits._source.uid,hits.hits._source.code');
		let body = {
			index: environment.urls.elastic.indices.lenders,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: ['uid', 'code'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	getLenders(): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.lenders + '/_search?sort=t:desc&size=10000&filter_path=hits.hits._source.*,hits.hits._id');
		  return this.http.get(this.apiBaseUrl + 'elastic/partners/lenders', this.esHeaders());

	}

	getProductLenders(search_text,pageNumber,perPage): Observable<any> {
		let searchParams= new HttpParams();
		searchParams= searchParams.append('search_text',search_text)
		searchParams= searchParams.append('page_number',pageNumber)
		searchParams= searchParams.append('per_page',perPage)
		let accessToken:any = localStorage.getItem('accessToken');
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.lenders + '/_search?sort=t:desc&size=10000&filter_path=hits.hits._source.*,hits.hits._id');
		  return this.http.get(this.oldBaseUrl + 'lenders',{headers:new HttpHeaders({'Token': `Bearer ${accessToken}`}),params:searchParams});

	}

	postLender(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'lenders', postData, this.esHeaders());
	}

	putLender(postData: any): Observable<any> {
		return this.http.put(this.apiBaseUrl + 'lenders/' + postData.id, postData, this.esHeaders());
	}

	// Product Versions
	getVersions(): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_versions + '/_search?' + 'size=10000&filter_path=hits.hits._source.*');
		let body = {
			index: environment.urls.elastic.indices.product_versions,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: ['*'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	getVersion(versionId: any): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.product_versions + '/_search?' + 'size=10000&filter_path=hits.hits._source.*&q=id:' + versionId);
		let body = {
			index: environment.urls.elastic.indices.product_versions,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: ['*'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	postVersion(postData: any): Observable<any> {
		return this.http.get(this.apiBaseUrl + 'product/versions' + postData);
	}

	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		return {headers};
	}
}
